import {actionFailure, actionStart, actionSuccess} from "../Generic/actions";
import api from "../../api/index";
import {GET_APP_SETTINGS, PATCH_APP_SETTINGS, SET_APP_SETTINGS} from "./constants";


export function setAppSettings(settings) {
  return (dispatch) => {
    dispatch({type: SET_APP_SETTINGS, payload: settings});
  }
}

export function getAppSettings() {
  return (dispatch) => {
    dispatch(actionStart(GET_APP_SETTINGS));
    const apiCall = api.getApplicationSettingsApiCall();
    apiCall
      .then((response) => {
        dispatch(setAppSettings(response.data));
        dispatch(actionSuccess(GET_APP_SETTINGS));
      })
      .catch(() => {
        dispatch(actionFailure(GET_APP_SETTINGS));
      });
    return apiCall;
  }
}

export function patchAppSettings(data) {
  return (dispatch) => {
    dispatch(actionStart(PATCH_APP_SETTINGS));
    const apiCall = api.patchApplicationSettingsApiCall(data);
    apiCall
      .then(() => {
        dispatch(actionSuccess(PATCH_APP_SETTINGS));
      })
      .catch(() => {
        dispatch(actionFailure(PATCH_APP_SETTINGS));
      });
    return apiCall;
  }
}