import Timeline from './Timeline/reducer'
import TokenSale from './TokenSale/reducer';
import WalletDetails from './WalletDetails/reducer';
import Investments from './Investments/reducer';
import Balance from './Balance/reducer';
import MyBalances from './Balance/reducer';
import Authentication from './Authentication/reducer';
import Loading from './Loading/reducer';
import Transactions from './Transactions/reducer';
import ReturnInvestments from './ReturnInvestments/reducer';
import UserList from './UserList/reducer';
import User from './User/reducer';
import ManageTokenSale from './ManageTokenSale/reducer';
import OngoingTokenSale from './OngoingTokenSale/reducer';
import TokenSaleDetails from './TokenSaleDetails/reducer';
import InvestmentReport from './InvestmentReport/reducer';
import TrustedAddress from './TrustedAddress/reducer';
import Language from './Language/reducer';
import AlertSystem from './AlertSystem/reducer';
import Country from './Country/reducer';
import UserShow from './UserShowList/reducer';
import Audit from './Audit/reducer';
import AppSettings from './AppSettings/reducer';
import Idnow from './Idnow/reducer';
import ActivePath from './ActivePath/reducer';
import Outer from './Outer/reducer';
import Fee from './Fee/reducer';
import Axios from './Axios/reducer';
import ActivityLog from './ActivityLog/reducer';
import KlickTipp from './KlickTipp/reducer';
import Sources from './Sources/reducer';
import Stats from './Stats/reducer';
import WalletPingInfo from './WalletPing/reducer';
import GeneralAdminSettings from './GeneralAdminSettings/reducer';
import BuildInfo from './BuildInfo/reducer';
import TermsAndConditions from './TermsAndConditions/reducer';
import Error from './Error/reducer';
import Prospectus from './Prospectus/reducer';
import Oauth from './oauth/reducer';
import AdminIps from './AdminIps/reducer';
import Novalnet from './Novalnet/reducer';
import ManageUser from './ManageUser/reducer';

export default {
  AlertSystem,
  Timeline,
  TokenSale,
  WalletDetails,
  Investments,
  Balance,
  Authentication,
  Loading,
  Error,
  Transactions,
  ReturnInvestments,
  UserList,
  User,
  ManageTokenSale,
  OngoingTokenSale,
  TokenSaleDetails,
  InvestmentReport,
  TrustedAddress,
  Language,
  Country,
  UserShow,
  Audit,
  AppSettings,
  Idnow,
  ActivePath,
  Outer,
  Fee,
  Axios,
  ActivityLog,
  KlickTipp,
  Sources,
  Stats,
  WalletPingInfo,
  MyBalances,
  GeneralAdminSettings,
  BuildInfo,
  TermsAndConditions,
  Prospectus,
  Oauth,
  AdminIps,
  Novalnet,
  ManageUser
}