const initialState = {
  authentication: {},
  loggedIn: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ('SET_LOGIN'): {
      return {...state, authentication: action.payload, loggedIn: true};
    }
    case ('SET_LOGOUT'): {
      return {...state, authentication: action.payload, loggedIn: false};
    }
    default:
      return {...state};
  }
}