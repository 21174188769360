import {SET_API_SERVER_BUILD, SET_AUTH_SERVER_BUILD, SET_CLIENT_BUILD, SET_WALLET_SERVER_BUILD} from "./constants";

const initialState = {
  clientBuildData: null,
  apiBuildData: null,
  authBuildData: null,
  walletBuildData: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CLIENT_BUILD: {
      return {...state, clientBuildData: action.payload};
    }
    case SET_API_SERVER_BUILD: {
      return {...state, apiBuildData: action.payload};
    }
    case SET_AUTH_SERVER_BUILD: {
      return {...state, authBuildData: action.payload};
    }
    case SET_WALLET_SERVER_BUILD: {
      return {...state, walletBuildData: action.payload};
    }
    default:
      return {...state};
  }
}