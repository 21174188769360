const initialState = {
  selectedLanguage: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ('SET_EU'): {
      return {...state, selectedLanguage: 'eu'};
    }
    case ('SET_EN'): {
      return {...state, selectedLanguage: 'en'};
    }
    case ('SET_DE'): {
      return {...state, selectedLanguage: 'de'};
    }
    case ('SET_NL'): {
      return {...state, selectedLanguage: 'nl'};
    }
    default:
      return {...state};
  }
}