import {SET_ARHCIVED_ADDRESS} from "./constants";

const initialState = {
  walletDetailsData: null,
  userWalletData: null,
  archivedData: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ('SET_WALLET_DETAILS'): {
      return {...state, walletDetailsData: action.payload};
    }
    case SET_ARHCIVED_ADDRESS: {
      return {...state, archivedData: action.payload};
    }
    default:
      return {...state};
  }
}