import {SET_A_SOURCE, SET_MY_SOURCES} from "./constants";

const initialState = {
  sourceListData: [],
  sourceViewData: undefined,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_MY_SOURCES: {
      return {...state, sourceListData: action.payload};
    }
    case SET_A_SOURCE: {
      return {...state, sourceViewData: action.payload};
    }
    default:
      return {...state};
  }
}