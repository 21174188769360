const initialState = {
  trustedAddressData: {}
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ('SET_TRUSTED_ADDRESS'): {
      return {...state, trustedAddressData: action.payload};
    }
    default:
      return {...state};
  }
}