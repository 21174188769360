import {
  ADD_TRUSTED_ADDRESS,
  DELETE_TRUSTED_ADDRESS,
  GET_TRUSTED_ADDRESS,
  POLL_TRUSTED_ADDRESS,
  REFETCH_TRUSTED_ADDRESS
} from "./constants";
import {actionFailure, actionStart, actionSuccess} from "../Generic/actions";
import api from '../../api/index';

export function setTrustedAddress(trustedAddresses) {
  return (dispatch) => {
    dispatch({type: 'SET_TRUSTED_ADDRESS', payload: trustedAddresses});
  }
}

export function getTrustedAddress() {
  return (dispatch) => {
    dispatch(actionStart(GET_TRUSTED_ADDRESS));
    const apiCall = api.getTrustedAddress();
    apiCall
      .then((response) => {
        if (response.status <= 204) {
          dispatch(setTrustedAddress(response.data));
        }
        dispatch(actionSuccess(GET_TRUSTED_ADDRESS));
      })
      .catch(() => {
        dispatch(actionFailure(GET_TRUSTED_ADDRESS));
      });
    return apiCall;
  }
}

export function searchTrustedAddress(params) {
  return () => {
    const apiCall = api.getTrustedAddress(params);
    apiCall
      .then(() => {
      })
      .catch(() => {
      });
    return apiCall;
  }
}


export function reFetchTrustedAddress() {
  return (dispatch) => {
    dispatch(actionStart(REFETCH_TRUSTED_ADDRESS));
    const apiCall = api.getTrustedAddress();
    apiCall
      .then((response) => {
        if (response.status <= 204) {
          dispatch(setTrustedAddress(response.data));
        }
        dispatch(actionSuccess(REFETCH_TRUSTED_ADDRESS));
      })
      .catch(() => {
        dispatch(actionFailure(REFETCH_TRUSTED_ADDRESS));
      });
    return apiCall;
  }
}

export function pollTrustedAddress() {
  return (dispatch) => {
    dispatch(actionStart(POLL_TRUSTED_ADDRESS));
    const apiCall = api.getTrustedAddress();
    apiCall
      .then((response) => {
        if (response.status <= 204) {
          dispatch(setTrustedAddress(response.data));
        }
        dispatch(actionSuccess(POLL_TRUSTED_ADDRESS));
      })
      .catch(() => {
        dispatch(actionFailure(POLL_TRUSTED_ADDRESS));
      });
    return apiCall;
  }
}


export function addTrustedAddress(address) {
  return (dispatch) => {
    dispatch(actionStart(ADD_TRUSTED_ADDRESS));
    const apiCall = api.addTrustedAddressApiCall(address);
    apiCall
      .then(() => {
        dispatch(actionSuccess(ADD_TRUSTED_ADDRESS));
      })
      .catch(() => {
        dispatch(reFetchTrustedAddress());
        dispatch(actionFailure(ADD_TRUSTED_ADDRESS));
      });
    return apiCall;
  }
}

export function deleteTrustedAddress(data) {
  return (dispatch) => {
    dispatch(actionStart(DELETE_TRUSTED_ADDRESS));
    const apiCall = api.deleteTrustedAddressApiCall(data);
    apiCall
      .then(() => {
        dispatch(actionSuccess(DELETE_TRUSTED_ADDRESS));
      })
      .catch(() => {
        dispatch(actionFailure(DELETE_TRUSTED_ADDRESS));
      });
    return apiCall;
  }
}
