export function roundToDecimals(val, decimals) {
  if (!isNaN(val)) {
    // value
    const value = Number(val);
    const decimalValue=Number(10 +'e'+ decimals);

    const rounded = Number(Math.round(value * decimalValue) / decimalValue);
    const floored = Number(Math.floor(value * decimalValue) / decimalValue);
    if (floored !== 0) {
      return rounded;
    } 
    if(value < 1e-12) {
      return 0
    }
    else {
      return value;
    }
  }
  return 0;
}
