import React, { Component } from "react";
import ReactPlaceholder from "react-placeholder";
import "nprogress/nprogress.css";
import "react-placeholder/lib/reactPlaceholder.css";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { setActiveContainer } from "../redux/ActivePath/actions";
import store from "../redux/store";


export default function AsyncFunc(importComponent) {

  function mapStateToProps() {
    return {}
  }

  class AsyncComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null,
      };
    }

    componentWillMount() {
      // Nprogress.start();
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    componentDidMount = async () => {
      const {dispatch} = store;
      this.mounted = true;
      const {default: Component} = await importComponent();
      // Nprogress.set(0.4);
      // Nprogress.done();
      localStorage.setItem("activeContainer", this.props.match.path);
      dispatch(setActiveContainer(this.props.match.path));
      if (this.mounted) {
        this.setState({
          component: <Component {...this.props} />,
        });
      }
    };


    render() {
      const Component = this.state.component || <div/>;
      return (
        <ReactPlaceholder type="text" rows={7} ready={Component !== null}>
          {Component}
        </ReactPlaceholder>
      );
    }
  }

  return withRouter(connect(mapStateToProps)(AsyncComponent));
}
