import {dummyTokenSaleDetails} from "../../constants/DummyData";

const initialState = {
  tokenSaleDetailsData: dummyTokenSaleDetails
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ('SET_TOKEN_SALE_DETAILS'): {
      return {...state, tokenSaleDetailsData: action.payload};
    }
    default:
      return {...state};
  }
}