export default class Api {
  constructor() {
    if (new.target === Api) {
      throw new TypeError("Cannot construct Abstract instances directly");
    }
  }

  /* -------------------- StatusServerUser --------------------*/
  statusServerUserApiCall() {
  }

  /* -------------------------- User ------------------------ */
  setKycApiCall() {
  } // Set the user kyc
  getKycApiCall() {
  } // Fetch user
  getUserListApiCall() {
  } // get user list

  /* -------------------------- Admin changes user ------------------------ */
  changeUserStatusApiCall() {
  }

  changeEmailApiCall() {
  }

  /* -------------------------- Authentication ------------------------ */
  logOut() {
  } // Logout

  /* -------------------------- Token Sale ------------------------ */
  getTokenSale() {
  } // Get information about token sale. If uuid is not present, it returns the latest tokensale
  createTokenSale() {
  } // Create new token sale.
  getCurrentReceivingAddress() {
  } // Get current receiving address set for tokenSale or specify id for specific tokensale
  setreceivingAddress() {
  } // Set receiving address
  getInvestmentInTokenSale() {
  } // get investments made in token sale
  invest() {
  } // Invest btc/ether/usd/euro on the platform
  refund() {
  } // Refund user's investment in the tokenSale
  startTokenSaleApiCall() {
  } // Start A token sale.
  stopTokenSaleApiCall() {
  } // Stop Token Sale.
  getDetailsOnTokenSale() {
  } // get details on token sale.
  updateTokenParameters() {
  } // Update token parameters.
  getTokenDetails() {
  } //
  setReceivingWalletApiCall() {
  } // Set receiving Wallet address
  getReceivingAddressApiCall() {
  } // get receiving wallet address
  updateTokenValueApiCall() {
  } // Set tokens value in euro
  disableAutoPriceUpdateApiCall() {
  } // Disable auto price update
  enableAutoPriceApiCall() {
  } //Enable auto price
  refundApiCall() {
  } // refund api call
  updateTokenSaleApiCall() {
  }

  deleteTokenSaleApiCall() {
  }

  recalculateEquivalenceApiCall() {
  }

  defaultTokenSaleApiCall() {
  }

  pingWalletGetDetailsApiCall() {
  } // Ping wallet get details api call

  /* -------------------------- Investments ------------------------ */
  getInvestmentsApiCall() {
  } // get investments

  /* -------------------------- Wallet and trusted address ------------------------ */
  getWalletDetails() {
  } // Get wallet details
  getTrustedAddress() {
  } // Get trusted Address
  addTrustedAddressApiCall() {
  } // Add trusted Address
  deleteTrustedAddressApiCall() {
  } // Remove Trusted Address
  archiveTrustedAddressApiCall(){
  }
  getArchivedAddressApiCall(){

  }
  getAllTokenSaleApiCall() {
  } // Get All token Sale
  getTransactionsApiCall() {
  }

  refreshBalanceApiCall() {
  } // Refresh balance

  /* -------------------------- Stripe charge ------------------------ */
  stripeCharge() {
  } // Charge a stripe payment

  /* -------------------------- Timeline ------------------------ */
  createTimelineApiCall() {
  } // Create timeline
  updateTimelineApiCall() {
  }

  getTimeLineApiCall() {
  }

  deleteTimelineApiCall() {
  }

  getTimelineItemApiCall() {
  } // get timeline items

  /* -------------------------- Media files ------------------------ */
  uploadMediaApiCall() {
  } // Upload media api call
  downloadMediaApiCall() {
  }

  /* -------------------- Countries --------------------*/
  updateCountryApiCall() {
  } // Update countries
  getCountriesApiCall() {
  } // Get countries

  /* -------------------- User Show --------------------*/
  getUserShowListApiCall() {
  }

  /* -------------------- Investment Report --------------------*/
  createInvestmentReportApiCall() {
  }

  getOneInvestmentReportApiCall() {
  }

  /* -------------------- Public api --------------------*/
  publicGetCountriesApiCall() {
  }

  /* -------------------- Application settings --------------------*/
  getApplicationSettingsApiCall() {
  }

  patchApplicationSettingsApiCall() {
  }

  /* -------------------- Refund --------------------*/
  requestRefundBtcApiCall() {
  }

  refundBtcApiCall() {
  }

  refundBtcFiatApiCall() {
  }

  requestRefundApiCall() {
  }

  cancelRequestRefundApiCall() {
  }

  /* -------------------- Idnow --------------------*/
  idnowEligibilityApiCall() {
  }

  idnowInitiateApiCall() {
  }

  idnowGetDetailsApiCall() {
  }

  /* -------------------- csv --------------------*/
  exportTransactionCsvApiCall() {
  }

  /* -------------------- Heart beat --------------------*/
  heartBeatApiCall() {
  }

  /* -------------------- fee --------------------*/
  getFeeApiCall() {
  }

  /* -------------------- Skip kyc --------------------*/
  skipKycApiCall() {
  }

  /* -------------------- Get activity log api --------------------*/
  getActivityLogApiCall() {
  }

  /* -------------------- KlickTipp --------------------*/
  getUserKlickTippApiCall() {
  }


  /* -------------------- Sources --------------------*/
  getMySourcesApiCall() {
  }

  getASourceApiCall() {
  }

  validateSourceApiCall() {
  }

  removeSourceApiCall() {
  }

  /* -------------------- Stats api --------------------*/
  getUserStatsApiCall() {
  }

  get2faStatsApiCall() {
  }

  getMessageBirdBalanceApiCall() {
  }

  getMessageBirdDetailsApiCall() {
  }

  getWalletStatsApiCall() {
  }

  /* -------------------- My preferences --------------------*/
  setMyPreferencesApiCall() {
  }

  /* -------------------- Admin general settings --------------------*/
  getGeneralAdminSettingsApiCall() {
  }

  getPublicGeneralSettingsApiCall() {
  }

  putGeneralAdminSettingsApiCall() {
  }

  /* -------------------- Build info --------------------*/
  clientBuildInfoApiCall() {
  }

  walletBuildInfoApiCall() {
  }

  apiServerBuildInfoApiCall() {
  }

  authServerBuildInfoApiCall() {
  }

  /* -------------------- Balance only --------------------*/
  walletBalanceApiCall() {
  }

  /* -------------------- Terms and conditions --------------------*/
  getTermsAndConditionsApiCall() {
  }

  /* -------------------- Prospectus --------------------*/
  getProspectusApiCall() {
  }

  readProspectusApiCall() {
  }

  /* -------------------- user consent --------------------*/
  consentApiCall() {

  }

  changeUsersConsentApiCall(){

  }

  /* -------------------- Investment report --------------------*/
  createWalletInvestmentReportApiCall() {
  }

  getWalletInvestmentReportApiCall() {
  }

  updateWalletReportApiCall() {
  }

  deleteWalletReportApiCall() {
  }

  takeSnapshotApiCall() {
  }

  getReturnsDeliveryApiCall() {
  }

  transferDeliveryApiCall() {
  }

  /* -------------------- IDnow update --------------------*/
  updateUserByAdminApiCall() {
  }

  get2faStatusOfUserApiCall() {
  }

  /* -------------------- Certificate api --------------------*/
  checkIfCertificateExistApiCall() {
  }

  /* -------------------- oauth --------------------*/
  createOauthClientApiCall() {
  }

  getOauthClientApiCall() {
  }

  deleteOAuthClientApiCall() {
  }

  editOAuthClientApiCall() {
  }

  /* -------------------- Whitelisted ip --------------------*/
  createWhitelistedAdminIpApiCall(){

  }

  getWhiteListedAdminIpApiCall(){

  }

  deleteWhitelistedAdminIpApiCall(){

  }


  /* -------------------- Novalnet --------------------*/
  initNovalnetPaymentApiCall(){

  }

  getInvoiceApiCall(){

  }

  /* -------------------- Novalnet toggles --------------------*/
  getAllNovalnetPaymentsApiCall(){

  }

  updateNovalnetApiCall(){

  }

  updateNovalnetTotalApiCall(){

  }

  getGtmTriggersApiCall(){

  }

  patchGtmTriggerApiCall(){

  }

}
