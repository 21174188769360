import React, { Component } from "react";
import { connect } from "react-redux";
import { setDe, setEn, setNl } from "../redux/Language/actions";
import { withRouter } from "react-router";

const queryString = require("query-string");

// create the function to notify React in your angular app
window.notifyReactNative = function(data, type) {
  window.postMessage(JSON.stringify({ data: data, type: type }), "*");
};

function mapStateToProps(state) {
  return {
    selectedLanguage: state.Language.selectedLanguage,
  };
}

class RouteChangeHandler extends Component {
  componentDidMount() {
    this._handleLanguageChange();
    this._handleRouteChange(this.props.location);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location !== prevProps.location) {
      this._handleRouteChange(this.props.location);
    }
  }

  _handleRouteChange(location) {
    console.log("Route changed. Router location is \n", location);
    console.log("Route changed. Window location is \n", window.location);
    window.postMessage(JSON.stringify({data: location.pathname.toString(), type: "URL_CHANGE"}), "*");
  }

  _handleLanguageChange = () => {
    const { search } = this.props.location;
    const parsed = queryString.parse(search);
    const { dispatch } = this.props;
    const { selectedLanguage } = this.props;
    if (selectedLanguage !== parsed.lang) {
      switch (parsed.lang) {
        case "en":
          dispatch(setEn());
          break;
        case "de":
          dispatch(setDe());
          break;
        case "nl":
          dispatch(setNl());
          break;
        default:
          return;
      }
    }
  };

  render() {
    return <div>{this.props.children}</div>;
  }
}

export default withRouter(connect(mapStateToProps)(RouteChangeHandler));
