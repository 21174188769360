import {SET_ADMIN_IPS} from "./constants";

const initialState = {
  adminIpsData: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ADMIN_IPS: {
      return {...state, adminIpsData: action.payload};
    }
    default:
      return {...state};
  }
}