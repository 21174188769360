import React, {Component} from 'react';
import {Route} from 'react-router-dom';
import {checkIsRequiredRole} from "../helpers/GenericHelpers";
import {Switch, withRouter} from "react-router";
import {connect} from "react-redux";

/* -------------------------- Map routes. Skip if not have access. ------------------------ */

function mapStateToProps(state) {
  return {
    role: state.User.roleData,
  };
}

class MainAppRouter extends Component {
  render() {
    const {url, role, routes} = this.props;

    return (
      <Switch>
        {routes.map(singleRoute => {
          const {path, exact, roles, ...otherProps} = singleRoute;
          const canAccess = checkIsRequiredRole(roles, [role]);
          if (canAccess) {
            return (
              <Route
                exact={exact}
                key={singleRoute.path}
                path={url + path}
                {...otherProps}
              />
            );
          }
          return null;
        })}
      </Switch>
    );
  }
}

export default withRouter(connect(mapStateToProps)(MainAppRouter));
