import React, {Component} from 'react';
import {Alert} from 'antd';
import {FormattedMessage} from 'react-intl';

class WarningBanner extends Component {
  render() {
    return (
      <div className="default-margin">
        <Alert
          message={
            <FormattedMessage
              id="warningBanner.title"
              defaultMessage="Exchange wallet addresses should not be used!"
            />
          }
          description=<FormattedMessage
            id="warningBanner.description"
            defaultMessage="Please use wallet address of which you are owner and have the private key."
          />
          type="error"
        />
      </div>
    );
  }
}

export default WarningBanner;
