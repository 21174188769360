import {actionFailure, actionStart, actionSuccess} from "../Generic/actions";
import api from "../../api/index";
import {HEART_BEAT} from "./constants";

export function heartBeat() {
  return (dispatch) => {
    dispatch(actionStart(HEART_BEAT));
    const apiCall = api.heartBeatApiCall();
    apiCall
      .then(() => {
        dispatch(actionSuccess(HEART_BEAT));
      })
      .catch(() => {
        dispatch(actionFailure(HEART_BEAT));
      });
    return apiCall;
  }
}