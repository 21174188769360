import React, {Component} from 'react';
import * as PropTypes from "prop-types";
import {Icon} from "antd";

class Error extends Component {
  static propTypes = {
    errorStatusCode: PropTypes.number,
    errorMessage: PropTypes.any,
  };

  render() {
    const {errorStatusCode, errorMessage} = this.props;
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          margin: '30px'
        }}
        className="faded medium-margin"
      >
        {/*<FloatingAstronaut />*/}
        <Icon type="warning" style={{fontSize: 72}}/>
        <h3><br/>{errorStatusCode || null}</h3>
        <h3><br/>{errorMessage || 'Hit a snag! Please try again later or contact admin.'}</h3>
      </div>
    );
  }
}

export default Error;
