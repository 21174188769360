export const removeTrailingSlash = str => {
  if (str.substr(-1) === "/") {
    return str.substr(0, str.length - 1);
  }
  return str;
};

export function checkIsRequiredRole(allowedRoles, userRoles) {
  if (allowedRoles === undefined) {
    return true;
  } else if (typeof allowedRoles === 'object') {
    if (allowedRoles.length === 0) {
      return true;
    }
  }
  for (let i = 0; i < userRoles.length; i += 1) {
    if (allowedRoles.indexOf(userRoles[i]) >= 0) {
      return true;
    }
  }
  return false;
}