import React, {Component} from 'react';
import {connect} from 'react-redux';
import Poller from '../helpers/Poller';
import _ from 'lodash';
import {FormattedMessage} from 'react-intl';
import {fetchMyBalance} from '../redux/Balance/actions';
import MyToastr from '../helpers/MyToastr';

function mapStateToProps(state) {
  return {
    balances: state.MyBalances.balanceData,
  };
}

class BalancePoller extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.poller = new Poller(this.pollingMyBalance);
  }

  componentDidMount() {
    this.pollingMyBalance();
    this.poller.start();
  }

  componentWillUnmount() {
    this.poller.stop();
    this.poller = null;
  }

  pollingMyBalance = () => {
    const { dispatch } = this.props;
    const dispatched = dispatch(fetchMyBalance());
    dispatched.catch(error => {
      MyToastr.error(
        _.get(
          error,
          'response.data.message',
          <FormattedMessage
            id="error.fetchingData"
            defaultMessage="Something went wrong. Error fetching data."
          />
        )
      );
    });
    return dispatched;
  };

  render() {
    return <div>{this.props.children}</div>;
  }
}

export default connect(mapStateToProps)(BalancePoller);
