import {SET_TERMS_AND_CONDITIONS} from "./constants";

const initialState = {
  termsAndConditions: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_TERMS_AND_CONDITIONS: {
      return {...state, termsAndConditions: action.payload};
    }
    default:
      return {...state};
  }
}