import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Divider, Input} from 'antd';
import {addTrustedAddress} from '../../redux/TrustedAddress/actions';
import _ from 'lodash';
import MyAlert from '../../components/MyAlert/MyAlert';
import styled from 'styled-components';
import {ADD_TRUSTED_ADDRESS} from '../../redux/TrustedAddress/constants';
import {loadingSelector} from '../../helpers/ApiSelector';
import {withRouter} from 'react-router';
import {FormattedMessage} from 'react-intl';
import MyToastr from '../../helpers/MyToastr';
import {pingWallet} from '../../redux/WalletPing/actions';
import WarningBanner from '../../components/WarningBanner/WarningBanner';

const HintHolder = styled.div`
  padding: 7px 7px 7px 0;
`;

const Heading = styled.h2.attrs({
  className: 'level-1 default-margin',
})`
@media only screen and (max-width: 600px) {
    width: 100%;
    padding: 0 15px;
  }

`;

const InputHolder = styled.div`
  width: 400px;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

const HintTitle = styled.h5.attrs({
  className: 'level-2',
})`
  margin: 0 15px 0 0;
`;

const ReceivingAddressHolder = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 400px;

  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 0 15px;
  }
`;

const isSetting = loadingSelector([ADD_TRUSTED_ADDRESS]);

function mapStateToProps(state) {
  return {
    isSetting: isSetting(state),
  };
}

class ReceivingAddressPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      receivingAddress: null,
      displayMessage: null,
    };
  }

  validate = () => {
    // if(isEthereumAddress(receivingAddress))
    this.setFirstReceivingAddress();
    // else
    //   dispatch(pushAlert({title: 'Not a valid Address', description: 'Please enter a valid Ethereum Wallet Address'}));
  };

  setFirstReceivingAddress = () => {
    const { receivingAddress } = this.state;
    const { dispatch } = this.props;
    dispatch(addTrustedAddress(receivingAddress))
      .then(() => {
        MyToastr.success(
          <FormattedMessage
            id="receivingAddressPage.set.success.title"
            defaultMessage="Address set!"
          />,
          <FormattedMessage
            id="receivingAddressPage.set.success.description"
            defaultMessage="Your receiving wallet address has been set! You can choose receiving address from your trusted address."
          />
        );
        dispatch(pingWallet())
          .then(() => this.props.history.push('/dashboard'))
          .catch(error => {
            MyToastr.error(_.get(error, 'response.data.message', <FormattedMessage id="error.fetchingData" defaultMessage="Something went wrong. Error fetching data." />));
          })
      })
      .catch(error => {
        MyToastr.error(
          _.get(
            error,
            'response.data.message',
            <FormattedMessage
              id="error.tryAgain"
              defaultMessage="Something went wrong. Please try again."
            />
          )
        );
      });
  };

  onChange = e => {
    this.setState({ receivingAddress: e.target.value });
  };

  render() {
    const { displayMessage, receivingAddress } = this.state;
    const { isSetting } = this.props;
    return (
      <div style={{ background: 'white', width: '100%' }} className="centered-flex">
        <p>&nbsp;</p>
        <MyAlert displayMessage={displayMessage} />
        <div>
          <Heading>
            <FormattedMessage
              id="receivingAddressPage.heading"
              defaultMessage="Add your Receiving Wallet address to get started !"
            />
          </Heading>
        </div>
          <Divider />



        {/*<p>&nbsp;</p>*/}
        <ReceivingAddressHolder>

          <p>
            <FormattedMessage
              id="receivingAddressPage.description"
              defaultMessage="This is the address you will be receiving SmartMark Tokens."
              values={{
                COMPANY_NAME: window.COMPANY_NAME
              }}
            />
          </p>
          <WarningBanner />
          <HintHolder>
            <HintTitle>
              <FormattedMessage id="walletAddress" defaultMessage="Wallet address" />
            </HintTitle>
          </HintHolder>
          {/*<Divider />*/}
          <InputHolder>
            <Input size={'large'} onChange={this.onChange} />
          </InputHolder>
          <p>&nbsp;</p>

          <Button
            block
            type={'primary'}
            style={{ height: '36px' }}
            size={'large'}
            htmlType="button"
            disabled={!receivingAddress}
            loading={isSetting}
            onClick={this.validate}
          >
            <FormattedMessage id="receivingAddressPage.set" defaultMessage="SET" />
          </Button>
        </ReceivingAddressHolder>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(ReceivingAddressPage));
