import React, {Component} from 'react';
import * as PropTypes from "prop-types";
import AmountNumber from "../AmountNumber";
import {FormattedMessage} from "react-intl";
import {Divider} from "antd";

class MyBalances extends Component {
  static propTypes = {
    token: PropTypes.number,
    eth: PropTypes.number,
    vertical: PropTypes.bool
  };

  render() {
    const {balances, vertical} = this.props;
    const {token, eth} = balances;
    return (
      <span>
        {
          !vertical ?
            <h5 className="text-white no-margin" style={{padding: '0 15px'}}>
              <FormattedMessage id="balance" defaultMessage="Balance"/>: &nbsp;
              <AmountNumber amount={Math.floor(token)}/>&nbsp;{window.TOKEN_UNIT}&nbsp;
              | &nbsp;<AmountNumber amount={eth}/>&nbsp;{global.ETH}&nbsp;
            </h5>
            :
            <div>
              <h4 className="text-white"><FormattedMessage id="balance" defaultMessage="Balance"/>&nbsp;</h4>
              <Divider/>
              <h6 className="text-white"><AmountNumber amount={Math.floor(token)}/>&nbsp;{window.TOKEN_UNIT}</h6>
              <h6 className="text-white"><AmountNumber amount={eth}/>{global.ETH}</h6>
            </div>
        }
      </span>
    );
  }
}

export default MyBalances;