import {SET_WALLET_PING_INFO} from "./constants";

const initialState = {
  walletPingInfoData: {
    BitCoinCashExplorer: "https://explorer.bitcoin.com/tbch/tx/",
    BitCoinExplorer: "https://live.blockcypher.com/btc-testnet/tx/",
    CoinMarketCapApiKey: "1a64d5d4-95a5-47d8-8381-f18cc70910db",
    EthereumExplorer: "https://rinkeby.etherscan.io",
    MaxTrusteeCount: 18,
    MinimumInvestment: "100",
    ReceivingAddress: "0x69ce6f09884533484cad42afcae24f5a9f67ab74",
    RefundApplicableDays: 14,
    BitCoinCashConfirmation: 6
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_WALLET_PING_INFO: {
      return {...state, walletPingInfoData: action.payload};
    }
    default:
      return {...state};
  }
}