import asyncComponent from "../helpers/AsyncFunc";

export const outerRoutes = [
  {
    exact: true,
    path: '/profile',
    component: asyncComponent(() => import('../containers/MyProfile/OuterKyc'))
  },
  {
    exact: true,
    path: '/identity',
    component: asyncComponent(() => import('../containers/Identity/OuterIdentity'))
  }
];