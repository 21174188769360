import React, {Component} from 'react';
import {connect} from 'react-redux';
import IdleTimer from 'react-idle-timer'
import {logOut} from "../redux/Authentication/actions";
import {message} from "antd";
import _ from "lodash";
import {heartBeat} from "../redux/HeartBeat/actions";
import {withRouter} from "react-router";
import {injectIntl} from "react-intl";

function mapStateToProps() {
  return {};
}

class IdleWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    // this.poller.start();
    // this.poller = setInterval(() => this.heartBeat(), 2 * 60 * 1000);
  }

  onActive = () => {
    // this.poller.start();

  };

  onAction = () => {
    this.heartBeat();
  };

  componentWillUnmount() {
    // this.poller.stop();
    clearInterval(this.poller);
    this.poller = null; // here...
  }

  heartBeat = () => {
    const {dispatch} = this.props;
    // const inactiveDuration = this.idleTimer.getLastActiveTime();
    // console.log(inactiveDuration);
    // if(inactiveDuration >= 60 * 1000)
    //   return;
    const dispatched = dispatch(heartBeat());
    dispatched
      .then(() => {
        console.log("Heart Beat success.");
      })
      .catch(error => {
        const status = _.get(error, 'response.status', null);
        if (status === 404 || status === 423 || status === 401) {
          this.props.history.push("/sign_in");
        }
        console.log("Heart Beat not successful.");
      });
    return dispatched;
  };

  onIdle = () => {
    this.poller.stop();
    this.logOut();
  };

  logOut = () => {
    const {dispatch} = this.props;
    dispatch(logOut())
      .then(() => {
        message.info(this.props.intl.formatMessage({
          id: 'message.info.pleaseReLogin',
          defaultMessage: "Please re-login to continue"
        }));
        this.props.history.push("/sign_in");
      })
      .catch(() => console.log('Signing out.'));
  };

  render() {
    return (
      <div>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref
          }}
          element={document}
          timeout={1 * 1000}
          // onIdle={this.onIdle}
          throttle={1000 * 2 * 60}
          onAction={this.onAction}
        />
        {this.props.children}
      </div>
    );
  }
}

export default withRouter(connect(
  mapStateToProps,
)(injectIntl(IdleWrapper)));