export function apiStart(label) {
  return (dispatch) => {
    // dispatch({type: 'API_START', payload: label});
    dispatch({type: label + "_REQUEST", payload: {}})
  }
}

export function apiSuccess(label) {
  return (dispatch) => {
    dispatch({type: label + "_SUCCESS", payload: label});
  }
}

export function apiError(label) {
  return (dispatch) => {
    dispatch({type: label + "_FAILURE", payload: label});
  }
}

export function apiEnd() {
  return () => {
    // dispatch({type: 'API_END', payload: label});
  }
}

export function accessDenied(pathName) {
  return (dispatch) => {
    dispatch({type: 'ACCESS_DENIED', payload: pathName});
  }
}