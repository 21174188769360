import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getProspectus} from "../redux/Prospectus/actions";
import MyToastr from "../helpers/MyToastr";
import {FormattedMessage} from "react-intl";
import {getAppSettings} from "../redux/AppSettings/actions";
import Error from "../components/Error/Error";
import _ from "lodash";
import LoadingOverlay from "../components/LoadingOverlay/LoadingOverlay";

function mapStateToProps(state) {
  return {
    applicationSettings: state.AppSettings.appSettingsData,
  };
}

class PreInformationWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialLoading: true,
    }
  }

  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(getAppSettings())
      .then(() => this.setState({initialLoading: false}))
      .catch(error => {
        this.setState({
          initialLoading: false,
          error: <Error errorMessage={_.get(error, 'response.data.message', <FormattedMessage id="error.fetchingData"
                                                                                              defaultMessage="Something went wrong. Error fetching data."/>)}/>
        });
      });
    dispatch(getProspectus())
      .catch(() =>
        MyToastr.error(
          <FormattedMessage id="preInfo.prospectus" defaultMessage="Could not fetch prospectus."/>
        )
      );

  }

  render() {
    const {initialLoading, error} = this.state;
    const { applicationSettings } = this.props;
    if (initialLoading) {
      return <LoadingOverlay />;
    }
    if (error) {
      return <div>{error}</div>;
    }
    if(applicationSettings)
      return (
        <div>
          {this.props.children}
        </div>
      );
    return <LoadingOverlay />;
  }
}

export default connect(mapStateToProps)(PreInformationWrapper);