import {dummyInvestmentsInATokenSale, dummyManageTokenSaleData} from "../../constants/DummyData";
import {SET_ALL_TOKEN_SALE, SET_TOKEN_DETAILS, SET_TOKEN_SALE} from "./constants";

const initialState = {
  allTokenSaleData: {},
  manageTokenSaleData: dummyManageTokenSaleData,
  tokenDetailData: {},
  investmentsInATokenSaleData: dummyInvestmentsInATokenSale,
  tokenSaleViewData: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ('SET_MANAGE_TOKEN_SALES'): {
      return {...state, manageTokenSaleData: action.payload};
    }
    case ('SET_INVESTMENTS_IN_A_TOKEN_SALE'): {
      return {...state, investmentsInATokenSaleData: action.payload};
    }
    case SET_ALL_TOKEN_SALE: {
      return {...state, allTokenSaleData: action.payload};
    }
    case SET_TOKEN_DETAILS: {
      return {...state, tokenDetailData: action.payload};
    }
    case SET_TOKEN_SALE: {
      return {...state, tokenSaleViewData: action.payload};
    }
    default:
      return {...state};
  }
}