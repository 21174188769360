import {actionFailure, actionStart, actionSuccess} from "../Generic/actions";
import {GET_GTM_TRIGGERS, PATCH_GTM_TRIGGERS} from "./constants";
import api from "../../api/index";

export function getGtmTriggers(){
  return(dispatch) => {
    dispatch(actionStart(GET_GTM_TRIGGERS));
    const apiCall = api.getGtmTriggersApiCall();
    apiCall
      .then(() => {
        dispatch(actionSuccess(GET_GTM_TRIGGERS));
      })
      .catch(() => {
        dispatch(actionFailure(GET_GTM_TRIGGERS));
      });
    return apiCall;
  }
}

export function patchGtmTriggers(triggerId){
  return(dispatch) => {
    dispatch(actionStart(PATCH_GTM_TRIGGERS));
    const apiCall = api.patchGtmTriggerApiCall(triggerId);
    apiCall
      .then(() => {
        dispatch(actionSuccess(PATCH_GTM_TRIGGERS));
      })
      .catch(() => {
        dispatch(actionFailure(PATCH_GTM_TRIGGERS));
      });
    return apiCall;
  }
}