import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Dropdown, Menu, message} from "antd";
import {Link} from "react-router-dom";
import {loadingSelector} from "../helpers/ApiSelector";
import {LOGOUT} from "../redux/Api/constants";
import {logOut} from "../redux/Authentication/actions";
import {getErrorMessage} from "../helpers/ErrorResponseHandler";
import _ from 'lodash';
import styled from 'styled-components';
import {currentApiServerApi} from "../api/apiurls";
import placeHolder from "../static/profile-pic-placeholder.jpeg";
import {FormattedMessage, injectIntl} from "react-intl";
import * as axios from "axios-cancel";

const PopItem = styled.p`
  margin: 7px 0;
  font-size: 1.2em;
`;

const loggingOut = loadingSelector([LOGOUT]);

function mapStateToProps(state) {
  return {
    loggingOut: loggingOut(state),
    me: state.User.me
  };
}

class TopAvatar extends Component {
  constructor(props) {
    super(props);
    this.getErrorMessage = getErrorMessage.bind(this);
  }

  logOut = () => {
    const {dispatch} = this.props;
    dispatch(logOut())
      .then(response => {
        if (response.status === 200) {
          message.info(_.get(response, 'message', this.props.intl.formatMessage({
            id: 'message.info.signedOut',
            defaultMessage: "Successfully signed out"
          })));
        } else {
          message.info(_.get(response, 'message', this.props.intl.formatMessage({
            id: 'message.info.signingOut',
            defaultMessage: "Signing out"
          })));
        }
        axios.cancelAll();
        this.props.history.push("/sign_in");
      })
      .catch(error => this.getErrorMessage(error));
  };

  render() {
    const {hideAccount} = this.props;
    const menu = (
      <Menu>
        {
          hideAccount ?
            null
            :
            <Menu.Item>
              <Link to="/account"><PopItem><FormattedMessage id="myProfile"
                                                             defaultMessage="My Profile"/></PopItem></Link>
            </Menu.Item>
        }
        <Menu.Item onClick={this.logOut}>
          <PopItem><FormattedMessage id="topBar.signOut" defaultMessage="Sign Out"/></PopItem>
        </Menu.Item>
      </Menu>
    );
    return (
      <div>
        <Dropdown trigger={['click']} overlay={menu} placement="bottomRight" overlayStyle={{width: '120px'}}>
          <span className="pointer-change-on-hover">
             <img
               src={currentApiServerApi + '/user/' + this.props.me.id + '/media/' + this.props.me.profile_image}
               alt="avatar"
               className="rounded centered-and-cropped"
               style={{height: '36px', width: '36px'}}
               onError={(e) => {
                 e.target.src = placeHolder
               }}
             />
          </span>
        </Dropdown>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
)(injectIntl(TopAvatar));