import {SET_APP_SETTINGS} from "./constants";

const initialState = {
  appSettingsData: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_APP_SETTINGS: {
      return {...state, appSettingsData: action.payload};
    }
    default:
      return {...state};
  }
}