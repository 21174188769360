import toastr from 'toastr';
import {message as antdMessage} from "antd";
import React, {Component} from 'react';
import {addLocaleData, injectIntl as baseInjectIntl, IntlProvider} from 'react-intl';
import hoistNonReactStatic from 'hoist-non-react-statics';

import en from 'react-intl/locale-data/en';
import eu from 'react-intl/locale-data/eu';
import de from 'react-intl/locale-data/de';
import nl from 'react-intl/locale-data/nl';

import messages_de from "../i18n/de.json";
import messages_en from "../i18n/en.json";
import messages_nl from "../i18n/nl.json";
import ReactDOMServer from "react-dom/server";
import {connect} from "react-redux";


toastr.options = {
  "closeButton": true,
  "debug": false,
  "newestOnTop": true,
  "progressBar": false,
  "preventDuplicates": true,
  "onclick": null,
  "showDuration": "1000",
  "hideDuration": "100",
  "timeOut": "5000",
  "extendedTimeOut": "5000",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
};

function injectIntl(WrappedComponent) {
  const WrapperComponent = baseInjectIntl(WrappedComponent);
  hoistNonReactStatic(WrapperComponent, WrappedComponent);
  return WrapperComponent;
}


const messages = {
  'eu': messages_en,
  'de': messages_de,
  'en': messages_en,
  'nl': messages_nl,
};
// language without region code

addLocaleData([...eu, ...en, ...de, ...nl]);

class MyToastr extends Component {

  static error(description, title = null) {
    // const { intl } = new IntlProvider({ locale: language, messages: messages }, {}).getChildContext();
    // notification.error({message: "Error!", description: description});
    toastr.error(
      ReactDOMServer.renderToStaticMarkup(
        <IntlProvider
          locale={localStorage.getItem('language') ? localStorage.getItem('language') : 'en'}
          messages={messages[localStorage.getItem('language') ? localStorage.getItem('language') : 'en']}
        >
          <span>{description}</span>
        </IntlProvider>
      ),
      title ?
        ReactDOMServer.renderToStaticMarkup(
          <IntlProvider
            locale={localStorage.getItem('language') ? localStorage.getItem('language') : 'en'}
            messages={messages[localStorage.getItem('language') ? localStorage.getItem('language') : 'en']}
          >
            <span>{title}</span>
          </IntlProvider>
        )
        :
        null
    );
  }

  static success(title, description) {
    // const { intl } = new IntlProvider({ locale: language, messages: messages[localStorage.getItem('lang') ? localStorage.getItem('lang') : 'eu'] }, {}).getChildContext();
    // notification.success({message: title, description: description });
    // const selected = store.state.Language.selectedLanguage;
    toastr.success(
      ReactDOMServer.renderToStaticMarkup(
        <IntlProvider
          locale={localStorage.getItem('language') ? localStorage.getItem('language') : 'en'}
          messages={messages[localStorage.getItem('language') ? localStorage.getItem('language') : 'en']}
        >
          <span>{description}</span>
        </IntlProvider>
      ),
      ReactDOMServer.renderToStaticMarkup(
        <IntlProvider
          locale={localStorage.getItem('language') ? localStorage.getItem('language') : 'en'}
          messages={messages[localStorage.getItem('language') ? localStorage.getItem('language') : 'en']}
        >
          <span>{title}</span>
        </IntlProvider>
      ));
  }

  static info(title, description) {
    // notification.info({message: title, description: description });
    toastr.info(
      ReactDOMServer.renderToStaticMarkup(
        <IntlProvider
          locale={localStorage.getItem('language') ? localStorage.getItem('language') : 'en'}
          messages={messages[localStorage.getItem('language') ? localStorage.getItem('language') : 'en']}
        >
          <span>{description}</span>
        </IntlProvider>
      ),
      ReactDOMServer.renderToStaticMarkup(
        <IntlProvider
          locale={localStorage.getItem('language') ? localStorage.getItem('language') : 'en'}
          messages={messages[localStorage.getItem('language') ? localStorage.getItem('language') : 'en']}
        >
          <span>{title}</span>
        </IntlProvider>
      ));
  }


  static warning(title, description) {
    // notification.warning({message: title, description: description });
    toastr.warning(
      ReactDOMServer.renderToStaticMarkup(
        <IntlProvider
          locale={localStorage.getItem('language') ? localStorage.getItem('language') : 'en'}
          messages={messages[localStorage.getItem('language') ? localStorage.getItem('language') : 'en']}
        >
          <span>{description}</span>
        </IntlProvider>
      ),
      ReactDOMServer.renderToStaticMarkup(
        <IntlProvider
          locale={localStorage.getItem('language') ? localStorage.getItem('language') : 'en'}
          messages={messages[localStorage.getItem('language') ? localStorage.getItem('language') : 'en']}
        >
          <span>{title}</span>
        </IntlProvider>
      ));
  }

  static loading(message) {
    antdMessage.loading(message);
  }
}

const mapStateToProps = (store) => ({
  selectedLanguage: store.getState().Language.selectedLanguage
});

export default injectIntl(connect(mapStateToProps)(MyToastr));