export const theme = {
  accentColor: '#f28e38',
};

export const myTheme = {
  bgColor: '#f4f7fa',
  primaryColor: '#0667d0',
  accentColor: '#f28e38',
  errorColor: "#d32f2f",
  gradientBg: 'linear-gradient(to right, #26a59a , #f1c71d)',
};

export const smTheme = {
  bgColor: "#F4F7FA",
  primaryColor: '#f28e38',
  errorColor: "#d32f2f",
  gradientBg: 'linear-gradient(to right, #26a59a , #f1c71d)',
};