import Cookies from 'universal-cookie';
import axios from 'axios';
import {currentApiServerApi} from "../../api/apiurls";
import {history} from "../../history";

const queryString = require('query-string');

const cookies = new Cookies();

export function setEn() {
  localStorage.setItem("language", "en");
  cookies.set('Language', 'en', {path: '/'});
  axios.get(currentApiServerApi + '/public/ping?changelang=en');
  return (dispatch) => {
    dispatch({type: 'SET_EN', payload: {}});
    const search = window.location.search;
    const parsed = queryString.parse(search);
    history.push({
      search: queryString.stringify({...parsed, lang: "en"})
    });
  }
}

export function setEu() {
  localStorage.setItem("language", "eu");
  cookies.set('Language', 'eu', {path: '/'});
  axios.get(currentApiServerApi + '/public/ping?changelang=en');
  return (dispatch) => {
    dispatch({type: 'SET_EU', payload: {}});
    const search = window.location.search;
    const parsed = queryString.parse(search);
    history.push({
      search: queryString.stringify({...parsed, lang: "eu"})
    })
  }
}

export function setDe() {
  localStorage.setItem("language", "de");
  cookies.set('Language', 'de', {path: '/'});
  axios.get(currentApiServerApi + '/public/ping?changelang=de');
  return (dispatch) => {
    dispatch({type: 'SET_DE', payload: {}});
    const search = window.location.search;
    const parsed = queryString.parse(search);
    history.push({
      search: queryString.stringify({...parsed, lang: "de"})
    })
  }
}


export function setNl() {
  localStorage.setItem("language", "nl");
  cookies.set('Language', 'nl', {path: '/'});
  axios.get(currentApiServerApi + '/public/ping?changelang=nl');
  return (dispatch) => {
    dispatch({type: 'SET_NL', payload: {}});
    const search = window.location.search;
    const parsed = queryString.parse(search);
    history.push({
      search: queryString.stringify({...parsed, lang: "nl"})
    })
  }
}