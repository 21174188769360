import {POP_ALERT, PUSH_ALERT} from "./constants";

const initialState = {
  alerts: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case PUSH_ALERT: {
      let newAlerts = {...state}.alerts.slice(0);
      newAlerts.push(action.payload);
      return {...state, alerts: newAlerts};
    }
    case POP_ALERT: {
      let newAlerts = {...state}.alerts.slice(0);
      newAlerts.pop();
      return {...state, alerts: newAlerts};
    }
    default:
      return {...state};
  }
}