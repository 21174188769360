export const CREATE_REPORT = "CREATE_REPORT";
export const GET_REPORT_VIEW = "GET_REPORT_VIEW";

export const SET_INVESTMENT_VIEW = "SET_INVESTMENT_VIEW";

export const CREATE_WALLET_REPORT = "CREATE_WALLET_REPORT";
export const GET_WALLET_REPORTS = "GET_WALLET_REPORTS";
export const SET_WALLET_REPORTS = "SET_WALLET_REPORTS";
export const GET_WALLET_REPORTS_VIEW = "GET_WALLET_REPORTS_VIEW";
export const SET_WALLET_REPORTS_VIEW = "SET_WALLET_REPORTS_VIEW";

export const POLL_WALLET_REPORTS_VIEW = "SET_WALLET_REPORTS_VIEW";

export const UPDATE_WALLET_REPORT = "UPDATE_WALLET_REPORT";
export const DELETE_WALLET_REPORT = "DELETE_WALLET_REPORT";
export const TAKE_SNAPSHOT = "TAKE_SNAPSHOT";

export const GET_RETURNS_DELIVERY = "GET_RETURNS_DELIVERY";
export const REFETCH_RETURNS_DELIVERIES = "REFETCH_RETURNS_DELIVERIES";
export const SET_RETURNS_DELIVERY = "SET_RETURNS_DELIVERY";

export const POLL_RETURNS_DELIVERY = "POLL_RETURNS_DELIVERY";
export const TRANSFER_DELIVERY = "TRANSFER_DELIVERY";