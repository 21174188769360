import {SET_ACTIVE_CONTAINER} from "./consants";

const initialState = {
  path: undefined,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_CONTAINER: {
      return {...state, path: action.payload};
    }
    default:
      return {...state};
  }
}