import React from "react";
import {Redirect, Route} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router";
import {statusServerUserApi} from "./api/apiurls";
import LoadingOverlay from "./components/LoadingOverlay/LoadingOverlay";

class PrivateAsyncRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
      checked: false,
    }
  }

  ping = () => {
    this.setState({pinging: true});
    fetch(statusServerUserApi.api, {credentials: 'include'})
      .then((response) => {
        if (!response.ok) {
          this.setState({checked: true, authenticated: false, pinging: false});
        } else {
          return response.json();
        }
      })
      .then(() => {
        this.setState({checked: true, authenticated: true, pinging: false});
      })
      .catch(() => {
        this.setState({checked: true, authenticated: false, pinging: false});
      })
  };

  componentDidMount() {
    this.isLoggedIn();
  }

  componentWillUnmount() {
    console.log("Unmounting private route")
  }


  static getDerivedStateFromProps(props, state) {
    // Any time the current user changes,
    // Reset any parts of state that are tied to that user.
    // In this simple example, that's just the email.
    if (props.loggedIn !== state.loggedIn) {
      return {
        loggedIn: props.loggedIn
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Typical usage (don't forget to compare props):
    if (prevState.loggedIn !== this.state.loggedIn && prevState.loggedIn !== undefined) {
      // Was logged in but just changed to not logged in.
      if (!this.state.loggedIn) {
        this.setState({justRedirect: true, checked: true, authenticated: false});
      }
      // this.isLoggedIn();
    }
  }

  isLoggedIn = () => {
    const {loggedIn} = this.props;
    this.setState({checked: false, authenticated: false});
    if (loggedIn) {
      this.setState({authenticated: true, checked: true});
    } else
    // this.resume();
    // this.clientSideResume();
    {
      this.ping();
    }
  };

  render() {
    const {component: Component, path, ...rest} = this.props;
    const {authenticated, checked, justRedirect, pinging} = this.state;

    const {pathname, search} = this.props.location;
    const next = (pathname || search) ? "?next=" + encodeURIComponent(pathname + search) : "";
    if (justRedirect) {
      return <Redirect to={"/sign_in" + next}/>;
    } else if (pinging) {
      return (
        <LoadingOverlay loading={pinging}/>
      );
    }
    if (checked) {
      if(authenticated){
        return (
          <div>
            <Route path={path} {...rest} render={props => <Component {...props} />}/>
          </div>
        )
      }
      else {
        return <Redirect to={"/sign_in" + next}/>;
      }
    }
    // return <Redirect  to={{pathname: "/sign_in", search: next, state: { from: this.props.location }}} />;
    else {
      return <LoadingOverlay loading={pinging}/>;
    }
  }
}


export default withRouter(connect(state => ({
  loggedIn: state.Authentication.loggedIn,
}))(PrivateAsyncRoute));