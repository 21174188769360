const statuses = [
  'incomplete',
  'complete',
  'pending_idnow_response',
  'pending_idnow_final_confirmation',
  'rejected_by_admin',
  'rejected_by_idnow',
  'verified_by_admin',
  'verified_by_idnow',
];


export function checkIfVerified(status) {
  return status === "verified_by_admin" || status === "verified_by_idnow";
}

export function checkIfRequiredEditPrompt(status) {
  return checkIfVerified(status);
}

export function checkIfFilled(status) {
  return statuses.indexOf(status) >= 1;
}

export function giveAllStatus(status) {
  // Verified by idnow
  if (statuses.indexOf(status) >= 6) {
    return 4;
  }
  // Rejected by idnow
  else if (statuses.indexOf(status) === 4 || statuses.indexOf(status) === 5) {
    return 3;
  }
  // Pending Idnow
  else if (statuses.indexOf(status) === 3) {
    return 2;
  }
  // Do Idnow
  else if (statuses.indexOf(status) === 1 || statuses.indexOf(status) === 2) {
    return 1;
  }
  // Not filled
  else if (statuses.indexOf(status) === 0) {
    return 0;
  }
}

export function giveIdnowStatus(status) {
  if (statuses.indexOf(status) >= 4) {
    return 2;
  } else if (statuses.indexOf(status) === 3) {
    return 1;
  } else {
    return 0;
  }
}