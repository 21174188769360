const options = [
  {
    key: 'dashboard',
    label: 'nav.dashboard',
    leftIcon: 'appstore',
  },
  {
    key: 'tokensale',
    label: 'nav.tokensale',
    leftIcon: 'shopping-cart',
  },
  {
    key: 'my_investments',
    label: 'nav.myInvestments',
    leftIcon: 'file-done',
  },
  {
    key: 'wallet',
    label: 'nav.wallet',
    leftIcon: 'wallet',
    children: [
      {
        key: 'mywallets/my_wallet',
        label: 'nav.wallet.myWallet',
      },
      // {
      //   key: 'wallet/balance',
      //   label: 'Balance',
      // },
      {
        key: 'mywallets/transactions',
        label: 'nav.wallet.transactions',
      },
      // {
      //   key: 'wallet/trusted_address',
      //   label: 'Trusted Address',
      // },
      {
        key: 'mywallets/returns',
        label: 'Returns',
      },
    ],
  },
  {
    key: 'account/profile',
    label: 'nav.account',
    leftIcon: 'user',
    children: [
      {
        key: 'account',
        label: 'nav.account.myProfile',
      },
      {
        key: 'account/security',
        label: 'nav.account.security',
      },
      {
        key: 'account/my_preferences',
        label: 'nav.account.preferences',
      },

      // {
      //   key: 'account/preferences',
      //   label: 'nav.account.preferences',
      // },
    ],
  },
  {
    hide: true,
    key: 'pages',
    label: 'Pages',
    leftIcon: 'ion-document-text',
    children: [
      {
        key: '404',
        label: 'sidebar.404',
        withoutDashboard: true,
      },
      {
        key: '500',
        label: 'sidebar.500',
        withoutDashboard: true,
      },
      {
        key: 'sign_in',
        label: 'sidebar.signIn',
        withoutDashboard: true,
      },
      {
        key: 'sign_up',
        label: 'sidebar.signUp',
        withoutDashboard: true,
      },
    ],
  },
  {
    roles: ['ADMIN'],
    key: 'manage',
    label: 'nav.manage',
    leftIcon: 'dashboard',
    children: [
      {
        key: 'manage/dashboard',
        label: 'nav.manage.dashboard',
      },
      {
        key: 'manage/users',
        label: 'nav.manage.users',
      },
      {
        key: 'manage/tokensale',
        label: 'nav.manage.tokensale',
      },
      {
        key: 'manage/token',
        label: 'nav.manage.companyToken',
      },
      {
        key: 'manage/timeline',
        label: 'nav.manage.timeline',
      },
      {
        key: 'manage/activity_log',
        label: 'nav.manage.activityLog',
      },
      {
        key: 'manage/investment_reports',
        label: 'nav.manage.investmentReport',
      },
    ],
  },
  {
    roles: ['ADMIN'],
    key: 'audit',
    label: 'nav.audit',
    leftIcon: 'table',
  },
  {
    roles: ['ADMIN'],
    key: 'settings',
    label: 'nav.settings',
    leftIcon: 'setting',
  },

];
export default options;
