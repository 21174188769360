import React, {Component} from 'react';
import {connect} from 'react-redux';
import {fetchMyBalance} from "../redux/Balance/actions";
import _ from 'lodash';
import Error from "../components/Error/Error";
import {FormattedMessage} from "react-intl";
import LoadingOverlay from "../components/LoadingOverlay/LoadingOverlay";

function mapStateToProps(state) {
  return {

  };
}

class BalanceWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialLoading: true
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchMyBalance())
      .then(() => {
        this.setState({initialLoading: false})
      })
      .catch(error => this.setState({
        initialLoading: false,
        error: <Error
          errorMessage={_.get(error, 'response.data.message', <FormattedMessage id="error.fetchingData"
                                                                                defaultMessage="Something went wrong. Error fetching data."/>)}/>
      }))
  }


  render() {
    const {error, initialLoading} = this.state;

    if (initialLoading) {
      return <LoadingOverlay/>;
    }
    if (error) {
      return <div>{error}</div>;
    }
    return (
      <div>
        {this.props.children}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
)(BalanceWrapper);