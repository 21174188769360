import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Alert} from "antd";
import _ from 'lodash';
import {popAlert} from "../../redux/AlertSystem/actions";
import styled from 'styled-components';
import moment from "moment";

const Wrapper = styled.div`
  margin: 0 0 30px 0;
`;

function mapStateToProps(state) {
  return {
    alerts: state.AlertSystem.alerts,
  };
}

class AlertSystem extends Component {

  onClose = () => {
    const {dispatch} = this.props;
    dispatch(popAlert());
  };

  render() {
    const {alerts} = this.props;
    if (alerts.length > 0) {
      const recentAlert = alerts[alerts.length - 1];
      const title = _.get(recentAlert, 'title', null);
      const description = _.get(recentAlert, 'description', " ");
      const type = _.get(recentAlert, 'type', "error");
      return (
        <Wrapper>
          <Alert
            key={moment()}
            showIcon
            closable
            type={type}
            afterClose={this.onClose}
            message={title}
            description={description}
          />
        </Wrapper>
      );
    }
    return null;
  }
}

export default connect(
  mapStateToProps,
)(AlertSystem);