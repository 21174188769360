export const GET_TOKEN_DETAILS = "GET_TOKEN_DETAILS";
export const SET_TOKEN_DETAILS = "SET_TOKEN_DETAILS";

export const GET_TOKEN_SALE = "GET_TOKEN_SALE";
export const SET_TOKEN_SALE = "SET_TOKEN_SALE";

export const CREATE_TOKEN_SALE = "CREATE_TOKEN_SALE";
export const GET_ALL_TOKEN_SALE = "GET_ALL_TOKEN_SALE";

export const UPDATE_TOKEN_SALE = "UPDATE_TOKEN_SALE";
export const DELETE_TOKEN_SALE = "DELETE_TOKEN_SALE";

export const SET_ALL_TOKEN_SALE = "SET_ALL_TOKEN_SALE";
export const START_TOKEN_SALE = "START_TOKEN_SALE";
export const STOP_TOKEN_SALE = "STOP_TOKEN_SALE";
export const POLL_TOKEN_SALE = "POLL_TOKEN_SALE";

export const POLL_INVESTMENT_IN_TOKEN_SALE = "POLL_INVESTMENT_IN_TOKEN_SALE";

export const DEFAULT_TOKEN_SALE = "DEFAULT_TOKEN_SALE";