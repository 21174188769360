import _ from 'lodash';
import {alertTypes} from "../constants/AlertTypes";

export default function handleErrorResponse(error) {
  if (error.response) {
    if (error.response.data) {
      if (error.response.data.message) {
        this.setState({displayMessage: error.response.data.message, alertType: alertTypes.ERROR});
      } else if (error.response.status) {
        this.setState({
          displayMessage: "Server responded with status code: " + error.response.status,
          alertType: alertTypes.ERROR
        })
      }
    } else if (error.response.status) {
      this.setState({
        displayMessage: "Server responded with status code: " + error.response.status,
        alertType: alertTypes.ERROR
      })
    }
  } else {
    this.setState({displayMessage: "No response from server.", alertType: alertTypes.ERROR});
  }
}

export function getErrorMessage(error) {
  if (error.response) {
    return _.get(error, 'response.data.message', "Something went wrong. Please try again.");
  }
  return "No response from server";
}