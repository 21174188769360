import React, {Component} from 'react';
import {connect} from 'react-redux';
import Poller from "../helpers/Poller";
import {fetchMe} from "../redux/User/actions";
import _ from "lodash";
import {FormattedMessage} from "react-intl";
import MyToastr from "../helpers/MyToastr";

function mapStateToProps() {
  return {};
}

class MePoller extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.poller = new Poller(this.pollingMe);
  }

  componentDidMount() {
    this.poller.start();
  }

  componentWillUnmount() {
    this.poller.stop();
    this.poller = null;
  }

  pollingMe = () => {
    const {dispatch} = this.props;
    const dispatched = dispatch(fetchMe());
    dispatched
      .then(response =>
        {console.log("Polling me response", response);}
      )
      .catch(error => {
        MyToastr.error(
          _.get(error, 'response.data.message', <FormattedMessage id="error.fetchingData"
                                                                  defaultMessage="Something went wrong. Error fetching data."/>)
        )
      });
    return dispatched;
  };


  render() {
    return (
      <div>
        {this.props.children}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
)(MePoller);