import React, { Component } from "react";
import styled from "styled-components";
import { BeatLoader } from "react-spinners";
import "./LoadingOverlay.css";

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background: #f4f7fa;
  position: fixed;
  z-index: 999;
`;

const Content = styled.div`
  height: 100px;
  width: 100px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

class LoadingOverlay extends Component {
  render() {
    return (
      <Wrapper>
        <Content>
          <LoadingContainer>
            <BeatLoader css="loader-css" sizeUnit={"px"} size={8} color={"#009bc3"} loading={this.props.loading} />
            <br />
            <p>LOADING</p>
          </LoadingContainer>
        </Content>
      </Wrapper>
    );
  }
}

export default LoadingOverlay;
