import {dummyUserListData} from "../../constants/DummyData";

const initialState = {
  userListData: dummyUserListData
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ('SET_USER_LIST'): {
      return {...state, userListData: action.payload};
    }
    default:
      return {...state};
  }
}