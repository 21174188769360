import env, {envType} from "./config";
import Implementation from "./implementation";

let api;

switch (env) {
  case envType.LOCAL:
    api = new Implementation('https://api.yesfifa.offlinepass.com');
    break;
  case envType.DEV:
    api = new Implementation('http://ec2-34-207-220-193.compute-1.amazonaws.com:8080');
    break;
  case envType.PROD:
    api = new Implementation('http://example.com/api');
    break;
  default:
    api = new Implementation();
}

export default api;
