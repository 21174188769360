import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Icon, Menu } from "antd";
import { withRouter } from "react-router";
import { checkIsRequiredRole, removeTrailingSlash } from "../../helpers/GenericHelpers";
import options from "./devOptions";
import * as PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import "./SideMenus.css";
import { optionsAliases } from "./optionsAliases";

const SubMenu = Menu.SubMenu;

function mapStateToProps(state) {
  return {
    role: state.User.roleData,
    active: state.ActivePath.path,
  };
}

class SideMenus extends Component {
  getMenuItem = ({singleOption, index}) => {
    const {key, label, leftIcon, children, roles} = singleOption;
    const isMenuVisible = checkIsRequiredRole(roles, [this.props.role]);
    if (!isMenuVisible) {
      return null;
    }
    const url = removeTrailingSlash(this.props.match.url);
    const marginOfMenuItem = index === 0 ? '0 7px 0 0': '0 7px';
    if (children) {
      return (
        <SubMenu
          style={styles.menuItem}
          key={key}
          title={
            <span style={{display: 'flex', alignItems: 'center', margin: marginOfMenuItem}}>
              <span style={styles.menuIcon} className="side-menu"><Icon type={leftIcon}/></span>
              <span style={styles.menuText}>
                <FormattedMessage
                  id={label}
                  values={{
                    COMPANY_NAME: window.COMPANY_NAME
                  }}
                />
              </span>
            </span>
          }
        >
          {children.map(child => {
            const linkTo = child.withoutDashboard
              ? `/${child.key}`
              : `${url}/${child.key}`;
            const isVisible = checkIsRequiredRole(child.roles, [this.props.role]);
            if (isVisible) {
              return (
                <Menu.Item key={child.key}>
                  <Link to={linkTo}>
                    <span style={styles.submenuText}>
                      <FormattedMessage
                        id={child.label}
                        values={{
                          COMPANY_NAME: window.COMPANY_NAME
                        }}
                      />
                    </span>
                  </Link>
                </Menu.Item>
              );
            }
            return null;
          })}
        </SubMenu>
      );
    }
    return (
      <Menu.Item key={key}>
        <Link to={`${url}/${key}`}>
          <span style={{margin: marginOfMenuItem}}>
            <span style={styles.menuIcon} className="side-menu"><Icon type={leftIcon}/></span>
            <span style={styles.menuText}>
              <FormattedMessage
                id={label}
                values={{
                  COMPANY_NAME: window.COMPANY_NAME
                }}
              />
            </span>
          </span>
        </Link>
      </Menu.Item>
    );
  };

  handleClick = () => {
    const {onClick} = this.props;
    if (onClick) {
      onClick();
    }
  };

  render() {
    const {mode, active} = this.props;
    // const active = localStorage.getItem("activeStorage");
    let activeKey = null;
    if (typeof active === 'string') {
      activeKey = active.substr(1);
    }
    let activeKeys = [activeKey];
    if(optionsAliases[activeKey])
      activeKeys.push(optionsAliases[activeKey]);
    return (
      <Menu
        selectedKeys={activeKeys}
        id="my-nav"
        mode={(mode === "horizontal" || mode === "inline") ? mode : "inline"}
        onClick={this.handleClick}
        onOpenChange={this.onOpenChange}
        style={{borderBottom: 0}}
      >
        {options.map((singleOption, index) =>
          singleOption.hide ? null : this.getMenuItem({singleOption, index})
        )}
      </Menu>
    );
  }
}

const styles = {
  menuIcon: {
    color: '#009bc3'
  },
  menuText: {
    color: '#009bc3',
    // color: '#708119e',
    fontSize: '1.12em',
    fontWeight: '400',
  },
  submenuText: {
    color: '#009bc3'
    // color: '#6c7293'
  },
};

SideMenus.propsTypes = {
  mode: PropTypes.string,
};

export default withRouter(connect(mapStateToProps)(SideMenus));
