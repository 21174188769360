import React, {Component} from 'react';
import {connect} from 'react-redux';
import {checkIfFilled, checkIfVerified} from '../../helpers/VerificationHelpers';
import {GtmTriggers} from '../../constants/GtmTriggers';
import {getGtmTriggers, patchGtmTriggers} from '../../redux/Gtm/actions';
import moment from 'moment';
import _ from 'lodash';

function mapStateToProps(state) {
  return {
    me: state.User.me,
    balance: state.MyBalances.balanceData,
  };
}

class Gtm extends Component {
  componentDidMount() {
    const { me, balance, dispatch } = this.props;
    this.pushTrigger(GtmTriggers.LOGGED_IN);
    this.pushTrigger(GtmTriggers.USER_STATE);
    dispatch(getGtmTriggers())
      .then(response => {
        const triggers = _.get(response, 'data.tags', null);
        if (triggers) {
          triggers.sort(function(a, b) {
            return moment(a.recorded_time) - moment(b.recorded_time);
          });
          const triggered = [];
          for (const trigger of triggers) {
            triggered.push(trigger.tag);
            if (trigger.hasOwnProperty('tag') && trigger.hasOwnProperty('id')) {
              this.pushTrigger(trigger.tag, () => this.sayTriggered(trigger.id));
            }
          }
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentWillUnmount() {
    console.log("GTM");
  }

  sayTriggered = triggerId => {
    const { me, balance, dispatch } = this.props;
    dispatch(patchGtmTriggers(triggerId)).catch(error => console.error(error));
  };

  pushTrigger = (trigger, callBack = null) => {
    const { me, balance } = this.props;
    window.dataLayer
    && window.dataLayer.push({
      event: trigger,
      userId: me.id,
      userIsKYCFilled: checkIfFilled(me.kyc_status),
      userIsKYCSuccess: checkIfVerified(me.kyc_status),
      userIsInvestor: balance.token > 0,
      eventCallback: callBack,
      eventTimeout: 2000,
    });
  };

  render() {
    return <div>{this.props.children}</div>;
  }
}

export default connect(mapStateToProps)(Gtm);
