import React, {Component} from 'react';
import {connect} from 'react-redux';
import AlertSystem from "../containers/AlertSystem/AlertSystem";

function mapStateToProps() {
  return {};
}

class AlertWrapper extends Component {
  render() {
    return (
      <div style={{width: '100%'}}>
        <AlertSystem/>
        {this.props.children}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
)(AlertWrapper);