import axios from "axios";
import _ from "lodash";
import { setLogOut } from "./redux/Authentication/actions";
import { history } from "./history";
import MyToastr from "./helpers/MyToastr";
import { FormattedMessage } from "react-intl";
import React from "react";
import { loadingSelector } from "./helpers/ApiSelector";
import { LOGOUT } from "./redux/Api/constants";

export default {
  setupInterceptors: store => {
    // Add a response interceptor
    axios.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        //catches if the session ended!
        const status = _.get(error, "response.status", null);
        if (status === 500) {
          history.push("/500");
        }

        // Exception to the rules based on url
        // let parts = error.config.url.split("/");
        // let lastPartOfUrl = parts[parts.length - 1]; // Or parts.pop();
        // if(lastPartOfUrl === "status")
        //   return Promise.reject(error);

        if (status === 419 || status === 423 || status === 401) {
          axios.cancelAll();
          // Just incase still logged in
          const state = store.getState();
          const isLoggingOutSelector = loadingSelector([LOGOUT]);
          const isLoggingOut = isLoggingOutSelector(state);
          if (state.Authentication.loggedIn === true && !isLoggingOut) {
            MyToastr.warning(
              <FormattedMessage id="network.sessionExpired" defaultMessage="Session expired!" />,
              <FormattedMessage id="network.pleaseReLogin" defaultMessage="Please re-login" />,
            );
            store.dispatch(setLogOut());
            return new Promise(() => {});
            // return Promise.reject(error);
          } else {
            store.dispatch(setLogOut());
            return new Promise(() => {});
            // return Promise.reject(error);
          }
        } else {
          return Promise.reject(error);
        }
      },
    );
  },
};
