import {SET_SKIPPED} from "./constants";

const initialState = {
  skipped: sessionStorage.getItem("skipped"),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SKIPPED: {
      return {...state, skipped: action.payload}
    }
    default:
      return {...state};
  }
}