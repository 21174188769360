import {dummyTimelineData} from "../../constants/DummyData";

const initialState = {
  timelineData: dummyTimelineData,
  timelineItemData: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ('SET_TIMELINE'): {
      return {...state, timelineData: action.payload};
    }
    case ('SET_TIMELINE_ITEM'): {
      return {...state, timelineItemData: action.payload};
    }
    default:
      return {...state};
  }
}