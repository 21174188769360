import {ongoingDummyTokenSaleData} from "../../constants/DummyData";

const initialState = {
  ongoingTokenSaleData: ongoingDummyTokenSaleData
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ('SET_ONGOING_TOKEN_SALES'): {
      return {...state, ongoingTokenSaleData: action.payload};
    }
    default:
      return {...state};
  }
}