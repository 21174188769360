import {dummyUserData} from "../../constants/DummyData";
import {RESET_2FA_STATUS_OF_USER, SET_2FA_STATUS_OF_USER} from "./constants";

const initialState = {
  me: null,
  userData: dummyUserData,
  kycData: null,
  roleData: null,
  other: null,
  other2fa: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ('SET_ME'): {
      return {...state, me: action.payload};
    }
    case ('SET_USER'): {
      return {...state, userData: action.payload};
    }
    case ('SET_KYC'): {
      return {...state, kycData: action.payload};
    }
    case ('SET_OTHER'): {
      return {...state, other: action.payload};
    }
    case ('SET_ROLE'): {
      return {...state, roleData: action.payload};
    }
    case SET_2FA_STATUS_OF_USER: {
      return {...state, other2fa: action.payload};
    }
    case RESET_2FA_STATUS_OF_USER: {
      return {...state, other2fa: null};
    }

    default:
      return {...state};
  }
}

