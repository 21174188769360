import {GET_AUDIT_TRANSACTIONS_TO_PRINT, SET_AUDIT_TRANSACTIONS} from "./constants";

const initialState = {
  auditData: null,
  auditToPrintData: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case SET_AUDIT_TRANSACTIONS: {
      return {...state, auditData: action.payload};
    }
    case 'SET_AUDITING_DATA': {
      return {...state, auditData: action.payload};
    }
    case GET_AUDIT_TRANSACTIONS_TO_PRINT: {
      return {...state, auditToPrintData: action.payload};
    }
    default:
      return {...state};
  }
}