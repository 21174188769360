// api/selectors.js
import _ from 'lodash';

export const createLoadingSelector = (actions) => (state) => {
  // returns true only when all actions is not loading
  return _(actions)
    .some((action) => _.get(state, `api.loading.${action}`));
};

export const loadingSelector = actions => state =>
  // returns true if an action is in loading
  actions.some(action => state.Loading[action]);

export const createErrorMessageSelector = (actions) => (state) => {
  // returns the first error messages for actions
  // * We assume when any request fails on a page that
  //   requires multiple API calls, we shows the first error
  return _(actions)
    .map((action) => _.get(state, `api.error.${action}`))
    .compact()
    .first() || '';
};

export const errorSelector = actions => state =>
  _(actions).map(action => state.Error[action]).compact().first() || undefined;