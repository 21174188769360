import { DEV } from "../constants/Environments";

const myEnv = process.env;

let baseurl = '';

if (myEnv.NODE_ENV === DEV)
{
  // baseurl = 'http://localhost:8080';
  // baseurl = 'https://startmark.offlinepass.com';
  // baseurl = 'https://token.startmark.de';
  // baseurl = 'http://192.168.11.44:8080';
  baseurl = "https://platform.startmark.de";
  // baseurl = window.BASE_URL;
}
else {
  baseurl = window.BASE_URL;
}

export const baseApi = baseurl;

