import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Dropdown, Icon, Menu} from "antd";
import styled from "styled-components";
import {setDe, setEn, setNl} from "../redux/Language/actions";

const PopItem = styled.p`
  margin: 7px 0;
  font-size: 1.2em;
`;

function mapStateToProps(state) {
  return {
    selectedLanguage: state.Language.selectedLanguage,
  };
}

class LanguageSelector extends Component {
  render() {
    const {dispatch} = this.props;
    const menu = (
      <Menu>
        <Menu.Item onClick={() => dispatch(setEn())}>
          <PopItem>EN</PopItem>
        </Menu.Item>
        <Menu.Item onClick={() => dispatch(setDe())}>
          <PopItem>DE</PopItem>
        </Menu.Item>
        <Menu.Item onClick={() => dispatch(setNl())}>
          <PopItem>NL</PopItem>
        </Menu.Item>

      </Menu>
    );
    const {selectedLanguage} = this.props;
    return (
      <div style={{margin: '0 25px 0 0'}}>
        <Dropdown overlay={menu} placement="bottomRight" overlayStyle={{width: '120px'}} style={{margin: 0}}
                  trigger={['click']}>
          <Button size="large" style={{marginLeft: 0, border: 0}} type="primary">
            <h6 className="text-white" style={{margin: 0}}>{selectedLanguage.toUpperCase()} <Icon type="down"/></h6>
          </Button>
        </Dropdown>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
)(LanguageSelector);