import {SET_AXIOS_SOURCE} from "./constants";

const initialState = {
  sourceData: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_AXIOS_SOURCE: {
      return {...state, sourceData: {...state.sourceData, [action.payload.label]: action.payload.source}};
    }
    default:
      return {...state};
  }
}