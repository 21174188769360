import {SET_KLICKTIPP} from "./constants";

const initialState = {
  klickTippData: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_KLICKTIPP: {
      return {...state, klickTippData: action.payload};
    }
    default:
      return {...state};
  }
}