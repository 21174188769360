import React, { Component } from "react";
import { message } from "antd";
import { Switch, withRouter } from "react-router";

import "./Overrides.css";
import UserWrapper from "./UserWrapper";
import "./toastr.css";
import IdleWrapper from "./IdleWrapper";
import { Route } from "react-router-dom";
import ReceivingAddressWrapper from "./ReceivingAddressWrapper";
import Inside from "./Inside";
import Outside from "./Outside";
import MePoller from "./MePoller";
import PreInformationWrapper from "./PreInformationWrapper";
import NetworkDetector from "../hoc/NetworkDetector";
import BalanceWrapper from "./BalanceWrapper";
import Gtm from "../containers/Gtm/Gtm";

message.config({
  duration: 3.5,
  maxCount: 3,
});

class MainApp extends Component {
  render() {
    const { role } = this.props;
    return (
      <UserWrapper>
        <IdleWrapper>
          <ReceivingAddressWrapper>
            <PreInformationWrapper>
              <MePoller>
                <BalanceWrapper>
                  <Gtm>
                    <Switch>
                      {role !== "ADMIN" ? (
                        <Route
                          key={this.props.match.url + "kyc"}
                          path={this.props.match.url + "kyc"}
                          render={() => <Outside />}
                        />
                      ) : null}
                      <Route path={this.props.match.url} render={() => <Inside />} />
                    </Switch>
                  </Gtm>
                </BalanceWrapper>
              </MePoller>
            </PreInformationWrapper>
          </ReceivingAddressWrapper>
        </IdleWrapper>
      </UserWrapper>
    );
  }
}

export default withRouter(NetworkDetector(MainApp));
