import axios from "axios";
import {accessDenied, apiEnd, apiError, apiStart, apiSuccess} from "./Api/actions";


const apiMiddleware = ({dispatch}) => next => action => {
  try {
    next(action)
  } catch (err) {
    console.error('Caught an exception!', err);
  }


  if (action.type !== 'API') {
    return;
  }

  const {
    url,
    method,
    data,
    onSuccess,
    onFailure,
    label,
    headers,
    params
  } = action.payload;

  // GET and DELETE methods use params while other methods may require sending some data to the server.
  // Thus, the variable, dataOrParams will hold any of the values, params or data depending on the method of the request.


  // axios default configs
  // axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || "";
  // axios.defaults.headers.common["Content-Type"]="application/json";
  // axios.defaults.headers.common["Authorization"] = `Bearer${accessToken}`;
  //
  axios.defaults.withCredentials = true;
  axios.defaults.crossDomain = true;
  axios.defaults.accessControlAllowOrigin = "*";


  if (label) {
    dispatch(apiStart(label));
  }

  axios
    .request({
      url,
      method,
      headers,
      params: params,
      data: data
    })
    .then(({data}) => {
      // Dispatch label for api Success to handle loading
      console.log("API MIDDLEWARE SUCCESS", data);
      dispatch(apiSuccess(label));
      // Dispatch data to be handled for success
      dispatch(onSuccess(data));
    })
    .catch(error => {
      // Dispatch label for api error to handle loading
      console.log("API MIDDLEWARE FAILURE", error);
      dispatch(apiError(label));
      // Dispatch error to be handled for failure
      dispatch(onFailure(error));

      if (error.response && error.response.status === 403) {
        dispatch(accessDenied(window.location.pathname));
      }
    })
    .finally(() => {
      if (label) {
        dispatch(apiEnd());
      }
    });
};

export default apiMiddleware;