import React, {Component} from 'react';
import {connect} from 'react-redux';
import Error from "../components/Error/Error";
import _ from 'lodash';
import ReceivingAddressPage from "../containers/ReceivingAddressPage/ReceivingAddressPage";
import {pushAlert} from "../redux/AlertSystem/actions";
import {Link} from "react-router-dom";
import styled from 'styled-components';
import AlertWrapper from "./AlertWrapper";
import OutsideLayoutWrapper from "./OutsideLayoutWrapper";
import {pingWallet} from "../redux/WalletPing/actions";
import LoadingOverlay from "../components/LoadingOverlay/LoadingOverlay";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

function mapStateToProps(state) {
  return {
    receivingAddress: state.TokenSale.receivingAddressData,
    pingInfo: state.WalletPingInfo.walletPingInfoData,
  };
}

class ReceivingAddressWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      called: false,
      initialLoading: true,
    }
  }

  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(pingWallet())
      .then(response => {
        this.setState({called: true, initialLoading: false});
        if (response.status === 204) {
          dispatch(pushAlert({
            title: "Receiving address not set!",
            description: <Link to="/get_started">Please set your receiving address</Link>,
            type: 'info'
          }));
        }
      })
      .catch(error => this.setState({
        called: true,
        initialLoading: false,
        error: <Error
          errorMessage={_.get(error, 'response.data.message', "Something went wrong. Could not fetch Receiving address")}/>
      }))
  }

  render() {
    const {called, initialLoading, error} = this.state;
    const receivingAddress = _.get(this.props, 'pingInfo.ReceivingAddress', null);
    if (error) {
      return <div>{error}</div>;
    }
    if (initialLoading) {
      return <LoadingOverlay/>;
    }
    if (called) {
      if (receivingAddress) {
        return (
          <div className="content-child">
            {this.props.children}
          </div>
        );
      } else {
        return (
          <OutsideLayoutWrapper>
            <Wrapper>
              <AlertWrapper>
                <ReceivingAddressPage/>
              </AlertWrapper>
            </Wrapper>
          </OutsideLayoutWrapper>
        )
      }
    }
    return null;
  }
}

export default connect(
  mapStateToProps,
)(ReceivingAddressWrapper);