import React from "react";

export const equivalence = {
  "btc": 0.00001,
  "ether": 0.01,
  "euro": 120,
  "usd": 100
};

export const dummyTimelineData = [
  {
    title: "End of Token Sale",
    description: "Last Token sale ended for session of 2019 with a total investment of XXX SmartTokens",
    date: "131-08-1982 10:20:56",
  },
  {
    title: "New Startup Added",
    description: "Drone Test www.dronetest.com",
    date: "13 May 2014 23:50:49,999",
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJyojFw_XaNuOogtCve05sQjEosZcs0cHUbWMxBTV7kxLkYapZ",
  },
  {
    title: "Investment report",
    description: "Investment reports generated for 2nd session , 2019.",
    date: "13 May 2014 23:50:49,999",
  }
];

export const dummyTokenSaleData = {
  "availableSupply": 1200,
  "contractAddress": "0x000000000009099",
  "equivalence": {
    "btc": 0.00001,
    "ether": 0.01,
    "euro": 120,
    "usd": 100
  },
  "hardCap": 900,
  "name": "TOKEN Tokensale, 2019",
  "softCap": 200,
  "symbol": "TOKEN",
  "totalSupply": 1200,
  "startDate": "12 March, 2019",
  "endDate": "12 June, 2019",
  "isLive": true,
};

export const dummyWalletDetailsData = {
  "addresses": {
    "content": [
      {
        "amount": 0,
        "description": "string",
        "fee": 0,
        "reference": "string"
      }
    ],
    "page_no": 0,
    "page_size": 0,
    "returned_element_count": 0,
    "total_elements": 0
  },
  "ether_balance": 0,
  "token_balance": 0
};

export const dummyWalletBalance = [
  {
    address: "0x32be343b94f860124dc4fee278fdcbd38c102d88",
    token_balance: 1000,
    ether_balance: 1200,
  },
  {
    address: "0x32be343b94f860124dc4fee278fdcbd38c102d88",
    token_balance: 1000,
    ether_balance: 1200,
  },
  {
    address: "0x32be343b94f860124dc4fee278fdcbd38c102d88",
    token_balance: 1000,
    ether_balance: 1200,
  },
  {
    address: "0x32be343b94f860124dc4fee278fdcbd38c102d88",
    token_balance: 1000,
    ether_balance: 1200,
  },
  {
    address: "0x32be343b94f860124dc4fee278fdcbd38c102d88",
    token_balance: 1000,
    ether_balance: 1200,
  },
  {
    address: "0x32be343b94f860124dc4fee278fdcbd38c102d88",
    token_balance: 1000,
    ether_balance: 1200,
  }
];

export const dummyTransactionData = [
  {
    date: "Feb-11-2019 10:46:26 AM +UTC",
    description: "Claimed return",
    hash: "0x28c40fff3b6f92ae405eb1f9087f14a5b39e9e73db2579a2684b177c9ebb6f71",
    operation: "RECEIVED",
    credit: {
      amount: 1000,
      type: "ETH",
    },
    debit: {
      amount: 0,
      type: null,
    }
  },
  {
    date: "Feb-11-2019 10:46:26 AM +UTC",
    description: "Bought TOKEN Tokens",
    hash: "0x28c40fff3b6f92ae405eb1f9087f14a5b39e9e73db2579a2684b177c9ebb6f71",
    operation: "PURCHASED",
    credit: {
      amount: 1000,
      type: "TOKEN",
    },
    debit: {
      amount: 100,
      type: "ETH",
    }
  },
  {
    date: "Feb-11-2019 10:46:26 AM +UTC",
    description: "Claimed return",
    hash: "0x28c40fff3b6f92ae405eb1f9087f14a5b39e9e73db2579a2684b177c9ebb6f71",
    operation: "RECEIVED",
    credit: {
      amount: 2000,
      type: "ETH",
    },
    debit: {
      amount: 0,
      type: null,
    }
  },
  {
    date: "Feb-11-2019 10:46:26 AM +UTC",
    description: "Bought TOKEN Tokens",
    hash: "0x28c40fff3b6f92ae405eb1f9087f14a5b39e9e73db2579a2684b177c9ebb6f71",
    operation: "PURCHASED",
    credit: {
      amount: 10000,
      type: "TOKEN",
    },
    debit: {
      amount: 1000,
      type: "EUR",
    }
  },
];

export const dummyReturnInvestmentsData = [
  {
    date: "Thu Feb 21 2019 14:29:47 GMT+0545",
    investment_report: {
      title: "Invest Return 2019 1st Session",
      description: "This is the investment return for Cum ventus velum, omnes parses locus lotus, alter cursuses.",
      report_url: "link",
    },
    return_amount: 1000,
    returned: false,
  },
  {
    date: "Thu Feb 21 2019 14:29:47 GMT+0545",
    investment_report: {
      title: "Invest Return 2019 1st Session",
      description: "This is the investment return for Cum ventus velum, omnes parses locus lotus, alter cursuses.",
      report_url: "link",
    },
    return_amount: 2000,
    returned: true,
  },
  {
    date: "Thu Feb 21 2019 14:29:47 GMT+0545",
    investment_report: {
      title: "Invest Return 2019 1st Session",
      description: "This is the investment return for Cum ventus velum, omnes parses locus lotus, alter cursuses.",
      report_url: "link",
    },
    return_amount: 1000,
    returned: false,
  },
  {
    date: "Thu Feb 21 2019 14:29:47 GMT+0545",
    investment_report: {
      title: "Invest Return 2019 1st Session",
      description: "This is the investment return for Cum ventus velum, omnes parses locus lotus, alter cursuses.",
      report_url: "link",
    },
    return_amount: 2000,
    returned: false,
  },
];

export const dummyUserListData = [
  {
    user_id: 123,
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    first_name: "Sudip",
    last_name: "Bhattarai",
    email: "sudip.bhattarai@gmail.com",
    nationality: "DE",
    registered_on: "123",
    verification: "VERIFIED",
  },
  {
    user_id: 123,
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    first_name: "Sudip",
    last_name: "Bhattarai",
    email: "sudip.bhattarai@gmail.com",
    nationality: "DE",
    registered_on: "123",
    verification: "UNVERIFIED",
  },
  {
    user_id: 123,
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    first_name: "Sudip",
    last_name: "Bhattarai",
    email: "sudip.bhattarai@gmail.com",
    nationality: "DE",
    registered_on: "123",
    verification: "AWAITING",
  },
  {
    user_id: 123,
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    first_name: "Sudip",
    last_name: "Bhattarai",
    email: "sudip.bhattarai@gmail.com",
    nationality: "DE",
    registered_on: "123",
    verification: "AWAITING",
  },
  {
    user_id: 123,
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    first_name: "Sudip",
    last_name: "Bhattarai",
    email: "sudip.bhattarai@gmail.com",
    nationality: "DE",
    registered_on: "123",
    verification: "VERIFIED",
  },
];

export const dummyUserData = {
  user_id: 123,
  email: 'sudipbhattarai@gmail.com',
  avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
  first_name: "Sudip",
  last_name: "Bhattarai",
  nationality: 'NP',
  address_line_1: 'Gwarko',
  address_line_2: null,
  address_line_3: null,
  postal_code: '44760',
  city: 'Kathmandu',
  country: 'NP',
  phone: '9849875416',
  dial_code: '977',
};

export const dummyManageTokenSaleData = [
  {
    token_sale_id: 123,
    token_name: "SMARTMARK Token",
    symbol: "TOKEN",
    name: "Company Pre Sale 2019 ",
    price: 100,
    total_supply: 1000,
    available_supply: 0,
    start_date: 123,
    end_date: 123,
    soft_cap: 200,
    hard_cap: 900,
    bonus: 10,
    live: false,
  },
  {
    token_sale_id: 1231,
    token_name: "SMARTMARK Token",
    name: "Company Token Sale 2029",
    symbol: "TOKEN",
    price: 100,
    total_supply: 1000,
    available_supply: 0,
    start_date: 123,
    end_date: 123,
    soft_cap: 200,
    hard_cap: 900,
    bonus: 0,
    live: false,
  },
  {
    token_sale_id: 1223,
    token_name: "SMARTMARK Token",
    name: "Company Token",
    symbol: "TOKEN",
    price: 100,
    total_supply: 1000,
    available_supply: 100,
    start_date: 123,
    end_date: 123,
    soft_cap: 200,
    hard_cap: 900,
    bonus: 0,
    live: false,
  },
  {
    token_sale_id: 121323,
    token_name: "SMARTMARK Token",
    name: "Company Token",
    symbol: "TOKEN",
    price: 100,
    total_supply: 1000,
    available_supply: 100,
    start_date: 123,
    end_date: 123,
    soft_cap: 200,
    hard_cap: 900,
    bonus: 0,
    live: false,
  },
];

export const ongoingDummyTokenSaleData = [
  {
    token_sale_id: 123,
    token_name: "SMARTMARK Token",
    name: "Company Token",
    symbol: "TOKEN",
    price: 100,
    total_supply: 1000,
    available_supply: 900,
    start_date: 123,
    end_date: 123,
    soft_cap: 200,
    hard_cap: 900,
    bonus: 0,
    live: true,
  }
];

export const dummyTokenSaleDetails = {
  token_sale_id: 123,
  token_name: "SMARTMARK Token",
  name: "Company Token",
  symbol: "token",
  price: 100,
  total_supply: 1000,
  available_supply: 900,
  start_date: "Thu Feb 21 2019 14:29:47 GMT+0545",
  end_date: "Thu Feb 22 2019 14:29:47 GMT+0545",
  soft_cap: 40,
  hard_cap: 100,
  bonus: 10,
  isLive: true,
};

export const dummyInvestmentsInATokenSale = [
  {
    id: 123,
    invested_by: {
      user_id: 123,
      email: 'sudipbhattarai@gmail.com',
      avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
      first_name: "Sudip",
      last_name: "Bhattarai",
      nationality: 'NP',
    },
    date: 'xxx',
    received_tokens: 1200,
    invested_amount: 12000,
    invested_currency_type: 'BTC',
  },
  {
    id: 1213,
    invested_by: {
      user_id: 123,
      email: 'sudipbhattarai@gmail.com',
      avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
      first_name: "Sudip",
      last_name: "Bhattarai",
      nationality: 'NP',
    },
    date: 'xxx',
    received_tokens: 1200,
    invested_amount: 12000,
    invested_currency_type: 'BTC',
  },
  {
    id: 123123,
    invested_by: {
      user_id: 123,
      email: 'sudipbhattarai@gmail.com',
      avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
      first_name: "Sudip",
      last_name: "Bhattarai",
      nationality: 'NP',
    },
    date: 'xxx',
    received_tokens: 1200,
    invested_amount: 12000,
    invested_currency_type: 'BTC',
  },
  {
    id: 121233,
    invested_by: {
      user_id: 123,
      email: 'sudipbhattarai@gmail.com',
      avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
      first_name: "Sudip",
      last_name: "Bhattarai",
      nationality: 'NP',
    },
    date: 'xxx',
    received_tokens: 1200,
    invested_amount: 12000,
    invested_currency_type: 'BTC',
  },
  {
    id: 112323,
    invested_by: {
      user_id: 123,
      email: 'sudipbhattarai@gmail.com',
      avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
      first_name: "Sudip",
      last_name: "Bhattarai",
      nationality: 'NP',
    },
    date: 'xxx',
    received_tokens: 1200,
    invested_amount: 12000,
    invested_currency_type: 'BTC',
  },
];

export const dummyInvestments = [
  {
    id: 112323,
    token_sale: {
      token_sale_id: 123,
      token_sale_name: 'Company Token Sale 2019',
    },
    date: "Thu Feb 21 2019 14:29:47 GMT+0545",
    debit: 1200,
    credit: 12000,
    currency_type: 'BTC',
    fee: 2,
    reference: "0xb97fd127b54316e21945f53ec3dae9f7d0fcda0b841d788e56d8fc097ecab71c",
  },
  {
    id: 112312323,
    token_sale: {
      token_sale_id: 123,
      token_sale_name: 'Company Token Sale 2019',
    },
    date: "Thu Feb 21 2019 14:29:47 GMT+0545",
    debit: 1200,
    credit: 12000,
    currency_type: 'BTC',
    fee: 2,
    reference: "0xb97fd127b54316e21945f53ec3dae9f7d0fcda0b841d788e56d8fc097ecab71c",
  },
  {
    id: 112321323,
    token_sale: {
      token_sale_id: 123,
      token_sale_name: 'Company Token Sale 2019',
    },
    date: "Thu Feb 21 2019 14:29:47 GMT+0545",
    debit: 1200,
    credit: 12000,
    currency_type: 'BTC',
    fee: 2,
    reference: "0xb97fd127b54316e21945f53ec3dae9f7d0fcda0b841d788e56d8fc097ecab71c",
  },
  {
    id: 111232323,
    token_sale: {
      token_sale_id: 123,
      token_sale_name: 'Company Token Sale 2019',
    },
    date: "Thu Feb 21 2019 14:29:47 GMT+0545",
    debit: 1200,
    credit: 12000,
    currency_type: 'BTC',
    fee: 2,
    reference: "0xb97fd127b54316e21945f53ec3dae9f7d0fcda0b841d788e56d8fc097ecab71c",
  },
];

export const dummyInvestmentReportList = [
  {
    id: 111232323,
    investment_report: {
      title: "Investment report , 2019",
      description: "Fortis parmas ducunt ad resistentia.",
      report: "link",
    },
    date: "Thu Feb 21 2019 14:29:47 GMT+0545",
    status: {
      total_received_by: 1000,
      total_remaining: 300,
    },
    total_amount: '1200',
  },
  {
    id: 111232323,
    investment_report: {
      title: "Investment report , 2019",
      description: "Fortis parmas ducunt ad resistentia.",
      report: "link",
    },
    date: "Thu Feb 21 2019 14:29:47 GMT+0545",
    status: {
      total_received_by: 1000,
      total_remaining: 300,
    },
    total_amount: '1200',
  },
  {
    id: 111232323,
    investment_report: {
      title: "Investment report , 2019",
      description: "Fortis parmas ducunt ad resistentia.",
      report: "link",
    },
    date: "Thu Feb 21 2019 14:29:47 GMT+0545",
    status: {
      total_received_by: 1000,
      total_remaining: 300,
    },
    total_amount: '1200',
  },
  {
    id: 111232323,
    investment_report: {
      title: "Investment report , 2019",
      description: "Fortis parmas ducunt ad resistentia.",
      report: "link",
    },
    date: "Thu Feb 21 2019 14:29:47 GMT+0545",
    status: {
      total_received_by: 1000,
      total_remaining: 300,
    },
    total_amount: '1200',
  },
];

export const dummySendUsers = [
  {
    id: 123,
    user: {
      user_id: 123,
      avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
      first_name: "Sudip",
      last_name: "Bhattarai",
      email: "sudip.bhattarai@gmail.com",
      nationality: "DE",
      registered_on: "123",
      verification: "VERIFIED",
    },
    send_status: false,
    amount: 100,
  },
  {
    id: 123,
    user: {
      user_id: 123,
      avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
      first_name: "Sudip",
      last_name: "Bhattarai",
      email: "sudip.bhattarai@gmail.com",
      nationality: "DE",
      registered_on: "123",
      verification: "VERIFIED",
    },
    send_status: false,
    amount: 100,
  },
  {
    id: 123,
    user: {
      user_id: 123,
      avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
      first_name: "Sudip",
      last_name: "Bhattarai",
      email: "sudip.bhattarai@gmail.com",
      nationality: "DE",
      registered_on: "123",
      verification: "VERIFIED",
    },
    send_status: false,
    amount: 100,
  },
  {
    id: 123,
    user: {
      user_id: 123,
      avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
      first_name: "Sudip",
      last_name: "Bhattarai",
      email: "sudip.bhattarai@gmail.com",
      nationality: "DE",
      registered_on: "123",
      verification: "VERIFIED",
    },
    send_status: false,
    amount: 100,
  },
];

export const dummyInvestmentReport = {
  id: 111232323,
  investment_report: {
    title: "Investment report , 2019",
    description: "Fortis parmas ducunt ad resistentia.Fortis parmas ducunt ad resistentia.Fortis parmas ducunt ad resistentia.Fortis parmas ducunt ad resistentia.Fortis parmas ducunt ad resistentia.Fortis parmas ducunt ad resistentia.Fortis parmas ducunt ad resistentia.Fortis parmas ducunt ad resistentia.",
    report: "link",
  },
  date: "Thu Feb 21 2019 14:29:47 GMT+0545",
  status: {
    total_received_by: 1000,
    total_remaining: 300,
  },
  total_amount: '1200',
};

export const dummyDocumentList = [
  {
    name: 'Investment Report 2019.pdf',
    uploaded_on: 'Thu Feb 21 2019 14:29:47 GMT+0545',
    report_id: 123,
  },
  {
    name: 'Investment Report 2019.pdf',
    uploaded_on: 'Thu Feb 21 2019 14:29:47 GMT+0545',
    report_id: 123,
  },
];
