import {dummyTokenSaleData} from "../../constants/DummyData";
import {SET_MY_RECEIVING_ADDRESS} from "./constants";

const initialState = {
  tokenSaleData: dummyTokenSaleData,
  receivingAddressData: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_TOKENSALE': {
      return {...state, tokenSaleData: action.payload};
    }
    case SET_MY_RECEIVING_ADDRESS: {
      return {...state, receivingAddressData: action.payload};
    }
    default:
      return {...state};
  }
}