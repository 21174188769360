import {SET_FEE} from "./constants";

const initialState = {
  feeData: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_FEE: {
      return {...state, feeData: action.payload};
    }
    default:
      return {...state};
  }
}