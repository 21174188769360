import {actionFailure, actionStart, actionSuccess} from "../Generic/actions";
import {GET_PROSPECTUS, READ_PROSPECTUS, SET_PROSPECTUS} from "./constants";
import api from "../../api/index";
import _ from 'lodash';

export function setProspectus(val) {
  return (dispatch) => {
    dispatch({type: SET_PROSPECTUS, payload: val});
  }
}

export function getProspectus() {
  return (dispatch) => {
    dispatch(actionStart(GET_PROSPECTUS));
    const apiCall = api.getProspectusApiCall();
    apiCall
      .then((response) => {
        dispatch(setProspectus(_.get(response.data, 'value', null)));
        dispatch(actionSuccess(GET_PROSPECTUS));
      })
      .catch(() => {
        dispatch(actionFailure(GET_PROSPECTUS));
      });
    return apiCall;
  }
}

export function readProspectus() {
  return (dispatch) => {
    dispatch(actionStart(READ_PROSPECTUS));
    const apiCall = api.readProspectusApiCall();
    apiCall
      .then(() => {
        dispatch(actionSuccess(READ_PROSPECTUS));
      })
      .catch(() => {
        dispatch(actionFailure(READ_PROSPECTUS));
      });
    return apiCall;
  }
}