import {SET_IDNOW_DETAILS} from "./constants";

const initialState = {
  idnowDetailsData: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_IDNOW_DETAILS: {
      return {...state, idnowDetailsData: action.payload};
    }
    default:
      return {...state};
  }
}