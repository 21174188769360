import {actionFailure, actionStart, actionSuccess} from "../Generic/actions";
import {PING_WALLET_GET_DETAILS, SET_WALLET_PING_INFO} from "./constants";
import api from "../../api";


export function setWalletPingInfo(address) {
  return (dispatch) => {
    dispatch({type: SET_WALLET_PING_INFO, payload: address});
  }
}

export function pingWallet() {
  return (dispatch) => {
    dispatch(actionStart(PING_WALLET_GET_DETAILS));
    return new Promise(function (resolve, reject) {
      api.pingWalletGetDetailsApiCall()
        .then(response => {
          try {
            dispatch(setWalletPingInfo(JSON.parse(window.atob(response.data))));
            dispatch(actionSuccess(PING_WALLET_GET_DETAILS));
            resolve(response);
          } catch (e) {
            dispatch(actionFailure(PING_WALLET_GET_DETAILS));
            reject({message: "Decode error"});
          }
        })
        .catch((error) => {
          reject(error);
          dispatch(actionFailure(PING_WALLET_GET_DETAILS));
        });
    })
  };
}