import axios from "axios";
import api from './api';
import {
  activityLogApi,
  addTrustedAddressApi,
  apiServerBuildApi,
  applicationSettingsApi,
  authServerApi,
  cancelRequestRefundApi,
  certificateApi,
  changeEmailApi,
  changeUserStatusApi,
  clientBuildInfoApi,
  createInvestmentReportApi,
  createOauthClientApi,
  createTimelineApi,
  createTokenSaleApi,
  createWhitelistedAdminIpApi,
  defaultTokenSaleApi,
  deleteOAuthClientApi,
  deleteTimeLineApi,
  deleteTrustedApi,
  deleteWalletReportApi,
  deleteWhitelistedAdminIpApi,
  disableAutoPriceApi,
  downloadApi,
  editOAuthClientApi,
  enableAutoPriceApi,
  equivalenceApi,
  exportTransactionCsv,
  feeApi,
  generalAdminSettingsApi,
  get2faStatsApi,
  get2faStatusOfUserApi,
  getAllNovalnetPaymentsApi,
  getAllTokenSaleApi,
  getArchivedAddressApi,
  getASourceApi,
  getCountriesApi,
  getGtmTriggersApi,
  getInvestmentsApi,
  getInvoiceApi,
  getKycApi,
  getMessageBirdBalanceApi,
  getMessageBirdDetailsApi,
  getMySourcesApi,
  getOAuthClientApi,
  getOneInvestmentReportApi,
  getReceivingAddressApi,
  getTimelineApi,
  getTimelineItemApi,
  getTokenDetailsApi,
  getTokenSaleApi,
  getTransactionsApi,
  getTrustedAddressApi,
  getUserListApi,
  getUserShowListApi,
  getUsersKlickTippApi,
  getUserStatsApi,
  getWalletDetailsApi,
  getWalletReportsApi,
  getWalletsStatApi,
  getWhiteListedAdminIpApi,
  heartBeatApiUrl,
  idnowEligibilityApi,
  idnowGetDetailsApi,
  idnowInitiateApi,
  initNovalnetPaymentApi,
  myPreferencesApi,
  patchGtmTriggerApi,
  pingWalletGetDetailsApi,
  prospectusApi,
  publicCountryApi,
  publicGeneralSettingsApi,
  publicTermsAndConditionApi,
  putGeneralAdminSettingApi,
  readProspectusApi,
  recalculateEquivalenceApi,
  refreshBalanceApi,
  refundApi,
  refundBtcApi,
  refundBtcFiatApi,
  removeSourceApi,
  requestRefundApi,
  requestRefundBtcApi,
  returnsDeliveryApi,
  setKycApi,
  setReceivingAddressApi,
  signOutApi,
  skipKycApi,
  startTokenSaleApi,
  statusServerUserApi,
  stopTokenSaleApi,
  stripeChargeApi,
  takeSnapshotApi,
  transerDeliveryApi,
  updateCountryApi,
  updateNovalnetPaymentApi,
  updateNovalnetPaymentTotalApi,
  updateTimelineApi,
  updateUserByAdminApi,
  updateWalletReportApi,
  uploadMediaApi,
  userConsentApi,
  userConsentChangeByAdminApi,
  validateSourceApi,
  walletBalanceApi,
  walletBuildApi,
  walletCreateInvestmentReportApi
} from "./apiurls";
import _ from 'lodash';

const TokenGenerator = require('uuid-token-generator');

export default class Implementation extends api {
  constructor(url) {
    super();
    this.url = url;
  }

  static validateStatusFunc(status){
    return status >= 200 && status < 300; // default
  };

  static validateStatusWithExtras(extra = []){
    return function validate(status) {
        return (status >= 200 && status <300) || extra.includes(status);
    }
  };

  static customApiCall(url = "", method = "GET", headers = null, params = null, data = null, cancelToken = null, extraStatuses = []) {
    const tokgen = new TokenGenerator();
    return axios
      .request({
        url,
        method,
        headers,
        params,
        data,
        cancelToken,
        validateStatus : this.validateStatusWithExtras(extraStatuses),
        requestId: tokgen.generate(),
      })
      .then((response) => {
        console.log("Api c  all Response:", response);
        return response;
      })
      .catch(error => {
        console.log("Api call Error:", error);
        if (_.get(error, 'constructor.name', null) === "Cancel" || axios.isCancel(error)) {
          console.log("Cancelled");
          return new Promise(() => {})
        } else if (error.response && error.response.status === 403) {
          // TODO : 403 api call here
          throw error;
        } else {
          throw error;
        }
      });
  }

  /* -------------------- StatusServerUser --------------------*/
  statusServerUserApiCall() {
    return Implementation.customApiCall(statusServerUserApi.api);
  }

  testApi() {
    return Implementation.customApiCall({});
  }

  /* -------------------------- Sign Out ------------------------ */
  logOut() {
    return Implementation.customApiCall(signOutApi.api, "GET");
  }

  /* -------------------------- Wallet and Trusted Address ------------------------ */
  getWalletDetails(params = null) {
    return Implementation.customApiCall(getWalletDetailsApi.api, "GET", null, params);
  }

  getTrustedAddress(params) {
    return Implementation.customApiCall(getTrustedAddressApi.api, "GET", null, params);
  }

  addTrustedAddressApiCall(data) {
    return Implementation.customApiCall(addTrustedAddressApi.api, addTrustedAddressApi.method, {'Content-Type': 'text/plain'}, null, data);
  }

  deleteTrustedAddressApiCall(data) {
    return Implementation.customApiCall(deleteTrustedApi.api, deleteTrustedApi.method, null, data);
  }

  archiveTrustedAddressApiCall(data) {
    return Implementation.customApiCall(deleteTrustedApi.api, deleteTrustedApi.method, null, data);
  }

  getArchivedAddressApiCall(params) {
    return Implementation.customApiCall(getArchivedAddressApi.api, getArchivedAddressApi.method, null, params);
  }

  getTransactionsApiCall(params) {
    super.getTransactionsApiCall();
    return Implementation.customApiCall(getTransactionsApi.api, getTransactionsApi.method, null, params);
  }

  refreshBalanceApiCall(address) {
    super.refreshBalanceApiCall();
    return Implementation.customApiCall(refreshBalanceApi(address).api, refreshBalanceApi(address).method);
  }

  /* -------------------------- Token Sale ------------------------ */
  getTokenDetails() {
    return Implementation.customApiCall(getTokenDetailsApi.api, "GET");
  }

  createTokenSale(data) {
    return Implementation.customApiCall(createTokenSaleApi.api, "POST", null, data);
  }

  updateTokenSaleApiCall(params) {
    return Implementation.customApiCall(createTokenSaleApi.api, "PATCH", null, params);
  }


  deleteTokenSaleApiCall(params) {
    super.deleteTokenSaleApiCall();
    return Implementation.customApiCall(createTokenSaleApi.api, "DELETE", null, params);
  }

  getTokenSale(params = null) {
    return Implementation.customApiCall(getTokenSaleApi.api, "GET", null, params);
  }

  getAllTokenSaleApiCall(params = null) {
    return Implementation.customApiCall(getAllTokenSaleApi.api, getAllTokenSaleApi.method, null, params)
  }

  startTokenSaleApiCall(params = null) {
    return Implementation.customApiCall(startTokenSaleApi.api, startTokenSaleApi.method, null, params);
  }

  stopTokenSaleApiCall(params) {
    super.stopTokenSaleApiCall();
    return Implementation.customApiCall(stopTokenSaleApi.api, stopTokenSaleApi.method, null, params);
  }

  setReceivingWalletApiCall(params) {
    return Implementation.customApiCall(setReceivingAddressApi.api, setReceivingAddressApi.method, null, params);
  }

  getReceivingAddressApiCall(params) {
    return Implementation.customApiCall(getReceivingAddressApi.api, getReceivingAddressApi.method, null, params);
  }

  getInvestmentsApiCall(params, sourceToken) {
    super.getInvestmentsApiCall();
    return Implementation.customApiCall(getInvestmentsApi.api, getInvestmentsApi.method, null, params, null, sourceToken);
  }

  updateTokenValueApiCall(params) {
    return Implementation.customApiCall(equivalenceApi.api, equivalenceApi.method, {'Content-Type': 'application/json'}, params);
  }

  recalculateEquivalenceApiCall() {
    super.recalculateEquivalenceApiCall();
    return Implementation.customApiCall(recalculateEquivalenceApi.api, recalculateEquivalenceApi.method, {'Content-Type': 'application/json'});
  }

  disableAutoPriceUpdateApiCall() {
    return Implementation.customApiCall(disableAutoPriceApi.api, disableAutoPriceApi.method);
  }

  enableAutoPriceApiCall(params = null) {
    super.enableAutoPriceApiCall();
    return Implementation.customApiCall(enableAutoPriceApi.api, enableAutoPriceApi.method, null, params);
  }

  refundApiCall(params) {
    super.refundApiCall();
    return Implementation.customApiCall(refundApi.api, refundApi.method, null, params);
  }

  defaultTokenSaleApiCall(params) {
    return Implementation.customApiCall(defaultTokenSaleApi.api, defaultTokenSaleApi.method, null, params);
  }


  /* -------------------------- Get user list ------------------------ */
  setKycApiCall(data) {
    return Implementation.customApiCall(setKycApi.api, setKycApi.method, null, null, data);
  }

  getKycApiCall(id) {
    return Implementation.customApiCall(getKycApi.api, getKycApi.method, null, {id: id});
  }

  getUserListApiCall(params = null) {
    return Implementation.customApiCall(getUserListApi.api, getUserListApi.method, null, params);
  }

  changeUserStatusApiCall(body) {
    return Implementation.customApiCall(changeUserStatusApi.api, changeUserStatusApi.method, {"Content-Type": "application/json"}, null, body);
  }

  changeEmailApiCall(body) {
    return Implementation.customApiCall(changeEmailApi.api, changeEmailApi.method, null, null, body);
  }

  /* -------------------------- Timeline ------------------------ */
  createTimelineApiCall(body) {
    super.createTimelineApiCall();
    return Implementation.customApiCall(createTimelineApi.api, createTimelineApi.method, {"Content-Type": "application/json"}, null, body);
  }

  updateTimelineApiCall(timelineId, body, params) {
    super.updateTimelineApiCall();
    return Implementation.customApiCall(updateTimelineApi.api + timelineId, updateTimelineApi.method, {"Content-Type": "application/json"}, params, body);
  }

  deleteTimelineApiCall(timelineId, params) {
    super.deleteTimelineApiCall();
    return Implementation.customApiCall(deleteTimeLineApi.api + timelineId, deleteTimeLineApi.method, null, params);
  }

  getTimeLineApiCall(params) {
    super.getTimeLineApiCall();
    return Implementation.customApiCall(getTimelineApi.api, getTimelineApi.method, null, params);
  }

  getTimelineItemApiCall(id) {
    super.getTimelineItemApiCall();
    return Implementation.customApiCall(getTimelineItemApi.api + id, getTimelineItemApi.method);
  }

  /* -------------------------- media files ------------------------ */
  uploadMediaApiCall(avatar) {
    const data = new FormData();
    data.append('mediaFile', avatar);
    return Implementation.customApiCall(uploadMediaApi.api, uploadMediaApi.method, {"Content-Type": "multipart/form-data"}, {type: "profile_picture"}, data);
  }

  downloadMediaApiCall(userId, resourceId) {
    return Implementation.customApiCall(downloadApi(userId, resourceId).api);
  }

  /* -------------------------- Stripe sofort ------------------------ */
  stripeCharge(body) {
    return Implementation.customApiCall(stripeChargeApi.api, "POST", {'Content-Type': 'application/json'}, body, body);
  }

  /* -------------------- Countries --------------------*/
  updateCountryApiCall(countryName, body) {
    return Implementation.customApiCall(updateCountryApi.api + countryName, updateCountryApi.method, {"Content-Type": "application/json"}, null, body);
  }

  getCountriesApiCall(params) {
    return Implementation.customApiCall(getCountriesApi.api, getCountriesApi.method, {"Content-Type": "application/json"}, params, null);
  }

  getUserShowListApiCall(params) {
    return Implementation.customApiCall(getUserShowListApi.api + "?" + params, getUserShowListApi.method, null, params, null);
  }

  /* -------------------- Investment Report --------------------*/
  createInvestmentReportApiCall(body) {
    return Implementation.customApiCall(createInvestmentReportApi.api, createInvestmentReportApi.method, null, null, body);
  }

  getOneInvestmentReportApiCall(id) {
    return Implementation.customApiCall(getOneInvestmentReportApi.api + {id}, getOneInvestmentReportApi.method, null, null, null);
  }

  /* -------------------- Public api --------------------*/
  publicGetCountriesApiCall() {
    super.publicGetCountriesApiCall();
    return Implementation.customApiCall(publicCountryApi.api, publicCountryApi.method, null, null, null);
  }

  /* -------------------- Application settings --------------------*/
  getApplicationSettingsApiCall() {
    super.getApplicationSettingsApiCall();
    return Implementation.customApiCall(applicationSettingsApi.api, "GET");
  }

  patchApplicationSettingsApiCall(data) {
    super.patchApplicationSettingsApiCall();
    return Implementation.customApiCall(applicationSettingsApi.api, "PATCH", null, null, data);
  }

  /* -------------------- Refund --------------------*/
  requestRefundApiCall(params) {
    return Implementation.customApiCall(requestRefundApi.api, requestRefundApi.method, null, params);
  }

  cancelRequestRefundApiCall(params){
    return Implementation.customApiCall(cancelRequestRefundApi.api, cancelRequestRefundApi.method, null, params);
  }

  requestRefundBtcApiCall(params) {
    super.refundBtcApiCall();
    return Implementation.customApiCall(requestRefundBtcApi.api, requestRefundBtcApi.method, null, params);
  }

  refundBtcApiCall(params) {
    super.refundBtcApiCall();
    return Implementation.customApiCall(refundBtcApi.api, refundBtcApi.method, null, params);
  }

  refundBtcFiatApiCall(params) {
    super.refundBtcApiCall();
    return Implementation.customApiCall(refundBtcFiatApi.api, refundBtcFiatApi.method, null, params);
  }

  /* -------------------- Idnow --------------------*/
  idnowEligibilityApiCall(params) {
    return Implementation.customApiCall(idnowEligibilityApi.api, idnowEligibilityApi.method, null, params);
  }

  idnowInitiateApiCall() {
    return Implementation.customApiCall(idnowInitiateApi.api, idnowInitiateApi.method);
  }

  idnowGetDetailsApiCall(params, extraStatuses) {
    return Implementation.customApiCall(idnowGetDetailsApi.api, idnowGetDetailsApi.method, null, params, null, null, extraStatuses);
  }

  /* -------------------- csv --------------------*/
  exportTransactionCsvApiCall(params) {
    return Implementation.customApiCall(exportTransactionCsv.api, exportTransactionCsv.method, null, params);
  }

  heartBeatApiCall() {
    return Implementation.customApiCall(heartBeatApiUrl.api, heartBeatApiUrl.method);
  }

  getFeeApiCall(params) {
    return Implementation.customApiCall(feeApi.api, feeApi.method, null, params);
  }

  skipKycApiCall(uuid) {
    return Implementation.customApiCall(skipKycApi(uuid).api, skipKycApi(uuid).method, null)
  }

  getActivityLogApiCall(params, data = {}) {
    return Implementation.customApiCall(activityLogApi.api, activityLogApi.method, {"Content-Type": "application/json"}, params, data);
  }

  getUserKlickTippApiCall(user) {
    return Implementation.customApiCall(getUsersKlickTippApi(user).api, getUsersKlickTippApi(user).method);
  }

  /* -------------------- Sources --------------------*/
  getMySourcesApiCall() {
    return Implementation.customApiCall(getMySourcesApi.api, getMySourcesApi.method);
  }

  getASourceApiCall(id) {
    return Implementation.customApiCall(getASourceApi(id).api, getASourceApi(id).method)
  }

  validateSourceApiCall(id) {
    return Implementation.customApiCall(validateSourceApi(id).api, validateSourceApi(id).method);
  }

  removeSourceApiCall(id) {
    return Implementation.customApiCall(removeSourceApi(id).api, removeSourceApi(id).method);
  }

  /* -------------------- Apis --------------------*/
  getUserStatsApiCall() {
    return Implementation.customApiCall(getUserStatsApi.api, getUserStatsApi.method);
  }

  get2faStatsApiCall() {
    return Implementation.customApiCall(get2faStatsApi.api, get2faStatsApi.method);
  }

  getMessageBirdBalanceApiCall() {
    return Implementation.customApiCall(getMessageBirdBalanceApi.api);
  }

  getMessageBirdDetailsApiCall(params) {
    return Implementation.customApiCall(getMessageBirdDetailsApi.api, getMessageBirdDetailsApi.method, null, params);
  }

  getWalletStatsApiCall() {
    return Implementation.customApiCall(getWalletsStatApi.api, getWalletsStatApi.method);
  }

  /* -------------------- Get wallet details --------------------*/
  pingWalletGetDetailsApiCall() {
    return Implementation.customApiCall(pingWalletGetDetailsApi.api);
  }

  setMyPreferencesApiCall(data) {
    return Implementation.customApiCall(myPreferencesApi.api, myPreferencesApi.method, {"Content-Type": "application/json"}, null, data);
  }

  /* -------------------- Admin general settings --------------------*/
  getGeneralAdminSettingsApiCall() {
    return Implementation.customApiCall(generalAdminSettingsApi.api, generalAdminSettingsApi.method);
  }

  getPublicGeneralSettingsApiCall() {
    return Implementation.customApiCall(publicGeneralSettingsApi.api);
  }

  putGeneralAdminSettingsApiCall(body) {
    return Implementation.customApiCall(putGeneralAdminSettingApi.api, putGeneralAdminSettingApi.method, {"Content-Type": "application/json"}, null, body);
  }

  /* -------------------- Build info --------------------*/
  clientBuildInfoApiCall() {
    return Implementation.customApiCall(clientBuildInfoApi.api);
  }

  walletBuildInfoApiCall() {
    return Implementation.customApiCall(walletBuildApi.api);
  }

  apiServerBuildInfoApiCall() {
    return Implementation.customApiCall(apiServerBuildApi.api);
  }

  authServerBuildInfoApiCall() {
    return Implementation.customApiCall(authServerApi.api);
  }

  /* -------------------- Balance only --------------------*/
  walletBalanceApiCall() {
    return Implementation.customApiCall(walletBalanceApi.api, walletBalanceApi.method);
  }

  /* -------------------- Terms and conditions --------------------*/
  getTermsAndConditionsApiCall() {
    return Implementation.customApiCall(publicTermsAndConditionApi.api);
  }

  /* -------------------- Investment report --------------------*/
  createWalletInvestmentReportApiCall(body) {
    return Implementation.customApiCall(walletCreateInvestmentReportApi.api, walletCreateInvestmentReportApi.method, null, body);
  }

  getWalletInvestmentReportApiCall(params) {
    return Implementation.customApiCall(getWalletReportsApi.api, getWalletReportsApi.method, null, params, null);
  }

  updateWalletReportApiCall(params, body) {
    return Implementation.customApiCall(updateWalletReportApi.api, updateWalletReportApi.method, null, params, body);
  }

  deleteWalletReportApiCall(params) {
    return Implementation.customApiCall(deleteWalletReportApi.api, deleteWalletReportApi.method, null, params);
  }

  takeSnapshotApiCall(params) {
    return Implementation.customApiCall(takeSnapshotApi.api, takeSnapshotApi.method, null, params);
  }

  getReturnsDeliveryApiCall(params, sourceToken) {
    return Implementation.customApiCall(returnsDeliveryApi.api, returnsDeliveryApi.method, null, params, null, sourceToken);
  }

  transferDeliveryApiCall(params) {
    return Implementation.customApiCall(transerDeliveryApi.api + '?' + params, transerDeliveryApi.method, null, params);
  }

  /* -------------------- Update IDnow count --------------------*/
  updateUserByAdminApiCall(userId, body) {
    return Implementation.customApiCall(updateUserByAdminApi(userId).api, updateUserByAdminApi(userId).method, null, null, body);
  }

  /* -------------------- read prospectus --------------------*/
  getProspectusApiCall() {
    return Implementation.customApiCall(prospectusApi.api, prospectusApi.method);
  }

  readProspectusApiCall() {
    return Implementation.customApiCall(readProspectusApi.api, readProspectusApi.method);
  }

  /* -------------------- user consent --------------------*/
  consentApiCall(body) {
    return Implementation.customApiCall(userConsentApi.api, userConsentApi.method, {'Content-Type': 'application/json'}, null, body);
  }

  changeUsersConsentApiCall(body){
   return Implementation.customApiCall(userConsentChangeByAdminApi.api, userConsentChangeByAdminApi.method, null, null, body);
  }


  /* -------------------- 2fa --------------------*/

  get2faStatusOfUserApiCall(userId) {
    return Implementation.customApiCall(get2faStatusOfUserApi(userId).api);
  }

  /* -------------------- Certificate --------------------*/
  checkIfCertificateExistApiCall(reportId, userId) {
    let url = certificateApi.api + "?report_id=" + reportId + "&user_id=" + userId;
    if (!userId) {
      url = certificateApi.api + "?report_id=" + reportId;
    }
    return Implementation.customApiCall(url);
  }

  /* -------------------- oauth --------------------*/
  createOauthClientApiCall(body) {
    return Implementation.customApiCall(createOauthClientApi.api, createOauthClientApi.method, null, null, body);
  }

  getOauthClientApiCall(params) {
    return Implementation.customApiCall(getOAuthClientApi.api, getOAuthClientApi.method, null, params);
  }

  deleteOAuthClientApiCall(clientId) {
    return Implementation.customApiCall(deleteOAuthClientApi(clientId).api, deleteOAuthClientApi(clientId).method);
  }

  editOAuthClientApiCall(clientId, oAuthClient) {
    return Implementation.customApiCall(editOAuthClientApi(clientId).api, "PUT", null, null, oAuthClient);
  }

  /* -------------------- Whitelisted ip --------------------*/
  createWhitelistedAdminIpApiCall(params) {
    return Implementation.customApiCall(createWhitelistedAdminIpApi.api, createWhitelistedAdminIpApi.method, null, params, null);
  }

  getWhiteListedAdminIpApiCall() {
    return Implementation.customApiCall(getWhiteListedAdminIpApi.api , getWhiteListedAdminIpApi.method);
  }

  deleteWhitelistedAdminIpApiCall(params){
    return Implementation.customApiCall(deleteWhitelistedAdminIpApi.api, deleteWhitelistedAdminIpApi.method, null, params);
  }

  /* -------------------- Novalnet payment --------------------*/
  initNovalnetPaymentApiCall(body){
    return Implementation.customApiCall(initNovalnetPaymentApi.api, initNovalnetPaymentApi.method, {"Content-Type": "application/json"}, null, body);
  }

  getInvoiceApiCall(params) {
    return Implementation.customApiCall(getInvoiceApi.api, getInvoiceApi.method, null, params)
  }

  /* -------------------- Novalnet toggles --------------------*/
  getAllNovalnetPaymentsApiCall(params){
    return Implementation.customApiCall(getAllNovalnetPaymentsApi.api, getAllNovalnetPaymentsApi.method, null, params);
  }

  updateNovalnetApiCall(body){
    return Implementation.customApiCall(updateNovalnetPaymentApi.api , updateNovalnetPaymentApi.method, null, null, body);
  }

  updateNovalnetTotalApiCall(body){
    return Implementation.customApiCall(updateNovalnetPaymentTotalApi.api , updateNovalnetPaymentTotalApi.method, null, null, body);
  }

  getGtmTriggersApiCall() {
    return Implementation.customApiCall(getGtmTriggersApi.api, getGtmTriggersApi.method);
  }

  patchGtmTriggerApiCall(triggerId) {
    return Implementation.customApiCall(patchGtmTriggerApi(triggerId).api, patchGtmTriggerApi(triggerId).method)
  }

}
