import {SET_USER_SHOW} from "./constants";

const initialState = {
  userShowData: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_SHOW: {
      return {...state, userShowData: action.payload};
    }
    default:
      return {...state};
  }
};