import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import './NetworkDetector.css';

export default function(ComposedComponent) {
  class NetworkDetector extends Component {
    state = {
      isDisconnected: false,
    };

    componentDidMount() {
      this.handleConnectionChange();
      window.addEventListener('online', this.handleConnectionChange);
      window.addEventListener('offline', this.handleConnectionChange);
    }

    componentWillUnmount() {
      window.removeEventListener('online', this.handleConnectionChange);
      window.removeEventListener('offline', this.handleConnectionChange);
      clearInterval(this.webPing);
      this.webPing = null;
    }

    handleConnectionChange = () => {
      const condition = navigator.onLine ? 'online' : 'offline';
      if (condition === 'online') {
        this.webPing = setInterval(() => {
          fetch('//google.com', {
            mode: 'no-cors',
          })
            .then(() => {
              this.setState({ isDisconnected: false }, () => {
                return clearInterval(this.webPing);
              });
            })
            .catch(() => this.setState({ isDisconnected: true }));
        }, 2000);
        return;
      }
      return this.setState({ isDisconnected: true });
    };

    render() {
      const { isDisconnected } = this.state;
      return (
        <div>
          {isDisconnected ? (
            <div>
              <div className="internet-error">
                <p><FormattedMessage id="noInternetConnection" defaultMessage="No Internet Connection"/></p>
              </div>
              {/*<Alert*/}
              {/*message={<FormattedMessage id="noInternetConnection" defaultMessage="No Internet Connection"/>}*/}
              {/*type="error"*/}
              {/*showIcon*/}
              {/*closable*/}
              {/*banner*/}
              {/*/>*/}
              {/*<div style={{background: '#FFDBDB', padding: '15px', width: '100%'}} >*/}
              {/*<FormattedMessage id="noInternetConnection" defaultMessage="No Internet Connection" />*/}
              {/*</div>*/}
            </div>
          ) : null}
          <ComposedComponent {...this.props} />
        </div>
      );
    }
  }

  return NetworkDetector;
}
