export const GtmTriggers = {
  LOGGED_IN : "LOGGED_IN",
  USER_STATE: "USER_STATE",
  KYC_FILLED: "KYC_FILLED",
  KYC_SUCCESS: "KYC_SUCCESS",
  INVESTED: "INVESTED"
};

export const GtmTriggersMapper = {
  KYC_SUCCESS: GtmTriggers.KYC_SUCCESS,
  INVESTMENT_SUCCESS: GtmTriggers.INVESTED,
  KYC_FILLED: GtmTriggers.KYC_FILLED,
}