import {
  SET_MESSAGEBIRD_BALANCE,
  SET_MESSAGEBIRD_DETAILS,
  SET_TFA_STATS,
  SET_USER_STATS,
  SET_WALLET_STATS
} from "./constants";

const initialState = {
  userStatData: null,
  tfaStatsData: null,
  messagebirdBalanceData: null,
  messageBirdDetailsData: null,
  walletStatsData: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_STATS: {
      return {...state, userStatData: action.payload};
    }
    case SET_TFA_STATS: {
      return {...state, tfaStatsData: action.payload};
    }
    case SET_MESSAGEBIRD_BALANCE: {
      return {...state, messagebirdBalanceData: action.payload};
    }
    case SET_MESSAGEBIRD_DETAILS: {
      return {...state, messageBirdDetailsData: action.payload};
    }
    case SET_WALLET_STATS: {
      return {...state, walletStatsData: action.payload};
    }
    default:
      return {...state};
  }
}