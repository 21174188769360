export default class poller {
  constructor(asyncFunction, duration = 7000) {
    this.isSetable = true;
    this.timer = 0;
    this.asyncFunction = asyncFunction;
    this.duration = duration;
    this.run = this._run.bind(this);
    this.start = this._start.bind(this);
    this.stop = this._stop.bind(this);
    this.call = this._call.bind(this);
    this.setTimer = this._setTimer.bind(this);
  };

  async _call() {
    try {
      const execute = await this.asyncFunction();
      console.log("Async polling response:", execute);
    } catch (e) {
      console.log("Async polling error:", e);
    }
    console.log("SET_TIMER_FUNCTION: isSetable", this.isSetable);
    if (this.isSetable) {
      this.setTimer(setTimeout(this.run, this.duration));
    }
  }

  _setTimer(timerFunction) {
    console.log("SET TIMER FUNCTION: setting ", timerFunction);
    this.timer = timerFunction;
    console.log("SET TIMER FUNCTION: after setting ", timerFunction);
  }

  _run() {
    const call = this.call();
    console.log("After call", call);
  }

  _start() {
    this.isSetable = true;
    this.setTimer(setTimeout(this.run, this.duration));
  }

  _stop() {
    if (this.timer) {
      this.isSetable = false;
      console.log("Stopping timer", this.timer);
      clearTimeout(this.timer);
      this.setTimer(0);
    }
  }
}