import React, {Component} from 'react';
import {connect} from 'react-redux';
import {message} from 'antd';
import {fetchKyc, setMe, setRole} from "../redux/User/actions";
import _ from 'lodash';
import LoadingOverlay from "../components/LoadingOverlay/LoadingOverlay";
import {Redirect, withRouter} from "react-router";

function mapStateToProps(state) {
  return {
    role: state.User.roleData,
    me: state.User.me,
  };
}

class UserWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetched: false,
      loading: true,
      mRole: null,
    }

  }

  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(fetchKyc())
      .then(response => {
        dispatch(setMe(response.data));
        const role = _.get(response, 'data.roles', null);
        if (role) {
          if (role[0] === "ADMIN" || role[0] === "SUPERADMIN") {
            dispatch(setRole("ADMIN"));
          } else {
            dispatch(setRole("USER"));
          }
        } else {
          message.error("Could not fetch role.");
          this.props.history.push('/sign_in')
        }
        this.setState({loading: false, fetched: true, mRole: role});
      })
      .catch(error => {
        // this.props.history.push('/sign_in');
        this.setState({loading: false});
        message.error(_.get(error, 'response.data.message', "Something went wrong. Could not fetch user."))
      });
  }

  render() {
    const {role, me} = this.props;
    const {loading} = this.state;

    if (role && me) {
      return (
        <div>
          {this.props.children}
        </div>
      );
    }

    if (loading) {
      return <LoadingOverlay/>;
    } else {
      return <Redirect to="/sign_in"/>
    }
  }
}

export default withRouter(connect(
  mapStateToProps,
)(UserWrapper));