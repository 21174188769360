import React, {Component} from 'react';
import {Alert} from "antd";
import * as PropTypes from "prop-types";

class MyAlert extends Component {
  render() {
    if (this.props.displayMessage) {
      return (
        <div style={{marginBottom: '15px'}}>
          <Alert
            showIcon
            banner
            closable
            type="warning"
            onClose={this.props.onClose}
            message={this.props.displayMessage}/>
        </div>
      );
    }
    return null;
  }
}

MyAlert.propTypes = {
  onClose: PropTypes.func,
  displayMessage: PropTypes.oneOf(
    [
      PropTypes.string,
      PropTypes.element
    ]
  )
};

export default MyAlert;