import React, {Component} from 'react';
import _ from 'lodash';
import {roundToDecimals} from "../helpers/MathHelpers";
import {FormattedNumber} from "react-intl";

class AmountNumber extends Component {
  render() {
    const {amount} = this.props;
    const rounded = roundToDecimals(_.defaultTo(amount, 0), 4);
    return (
      <FormattedNumber
        value={rounded}
        maximumFractionDigits={8}
        minimumSignificantDigits={1}
      />
    );
  }
}

export default AmountNumber;
