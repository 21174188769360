import {SET_PROSPECTUS} from "./constants";

const initialState = {
  prospectusData: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROSPECTUS: {
      return {...state, prospectusData: action.payload}
    }
    default:
      return {...state};
  }
}