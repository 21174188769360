import {POP_ALERT, PUSH_ALERT} from "./constants";
import _ from 'lodash';

export function pushAlert(alert) {
  return (dispatch, getState) => {

    // Return if no alert
    if (!alert) {
      return;
    }

    // Only dispatch when alert has title
    if (_.get(alert, 'title', null)) {
      const newAlert = Object.assign({}, {
        title: alert.title,
        description: _.get(alert, 'description', " "),
        type: _.get(alert, 'type', "error")
      });

      // Return if already same alert
      const {alerts} = getState().AlertSystem;
      const recentAlert = alerts[alerts.length - 1];

      if (recentAlert) {
        if (_.isEqual(recentAlert, newAlert)) {
          return;
        }
      }


      dispatch({type: PUSH_ALERT, payload: newAlert})
    }
  }
}

export function popAlert(alert) {
  return dispatch => {
    dispatch({type: POP_ALERT, payload: alert})
  }
}