import Media from "react-media";
import React from "react";

export function Desktop(props) {
  return (
    <Media query="(min-width: 992px)" {...props}>
      {matches =>
        matches ? props.children : null
      }
    </Media>
  );
}

export function Tablet(props) {
  return (
    <Media query="(max-width: 992px) and (min-width: 600px)" {...props}>
      {matches =>
        matches ? props.children : null
      }
    </Media>
  );
}

export function MD(props) {
  return (
    <Media query="(min-width: 600px)" {...props}>
      {matches =>
        matches ? props.children : null
      }
    </Media>
  );
}

export function Mobile(props) {
  return (
    <Media query="(max-width: 599px)" {...props} >
      {matches =>
        matches ? props.children : null
      }
    </Media>
  );
}


export function MD768(props) {
  return (
    <Media query="(min-width: 768px)" {...props}>
      {matches =>
        matches ? props.children : null
      }
    </Media>
  );
}

