import {SET_GENERAL_ADMIN_SETTINGS, SET_PUBLIC_GENERAL_SETTINGS} from "./constants";

const initialState = {
  generalAdminSettingsData: [],
  allowedPaymentMethods: null,
  publicSettingsData: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_GENERAL_ADMIN_SETTINGS: {
      return {...state, generalAdminSettingsData: action.payload};
    }
    case SET_PUBLIC_GENERAL_SETTINGS: {
      return {...state, publicSettingsData: action.payload};
    }
    default:
      return {...state};
  }
}