export function actionStart(label) {
  return (dispatch) => {
    // dispatch({type: 'API_START', payload: label});
    dispatch({type: label + "_REQUEST", payload: {}})
  }
}

export function actionSuccess(label, successResponse = undefined) {
  return (dispatch) => {
    dispatch({type: label + "_SUCCESS", payload: {label, successResponse: successResponse}});
  }
}


export function actionFailure(label, error = undefined) {
  return (dispatch) => {
    dispatch({type: label + "_FAILURE", payload: {label, error}});
  }
}
