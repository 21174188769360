import {GET_MY_BALANCE, SET_MY_BALANCE} from "./constants";
import {actionFailure, actionStart, actionSuccess} from "../Generic/actions";
import api from "../../api/index";
import _ from "lodash";

export function setBalance(balances) {
  return (dispatch, getState) => {
    const state = getState();
    if (!_.isEqual(state.MyBalances.balanceData, balances)) {
      dispatch({type: SET_MY_BALANCE, payload: balances});
    }
  }
}

export function fetchMyBalance() {
  return (dispatch) => {
    dispatch(actionStart(GET_MY_BALANCE));
    const apiCall = api.walletBalanceApiCall();
    apiCall
      .then((response) => {
        const ethBalance = _.get(response, 'data.ether', 0);
        const tokenBalance = _.get(response, 'data.token', 0);
        dispatch(setBalance({token: tokenBalance, eth: ethBalance}));
        dispatch(actionSuccess(GET_MY_BALANCE));
      })
      .catch(() => {
        dispatch(actionFailure(GET_MY_BALANCE));
      });
    return apiCall;
  }
}