import asyncComponent from '../helpers/AsyncFunc';

const routes = [
  {
    exact: true,
    path: '',
    component: asyncComponent(() => import('../containers/Dashboard/Dashboard'))
  },
  {
    exact: true,
    path: 'dashboard',
    component: asyncComponent(() => import('../containers/Dashboard/Dashboard'))
  },
  {
    exact: true,
    path: 'dashboard/404',
    component: asyncComponent(() => import('../containers/Page/Page404'))
  },
  {
    exact: true,
    path: 'dashboard/500',
    component: asyncComponent(() => import('../containers/Page/Page500'))
  },
  {
    exact: false,
    path: 'tokensale',
    component: asyncComponent(() => import('../containers/Status/Status'))
  },
  {
    exact: true,
    path: 'novalnet/payment/success',
    component: asyncComponent(() => import('../containers/Buy/FiatPaymentSuccess'))
  },
  {
    exact: true,
    path: 'novalnet/payment/pending',
    component: asyncComponent(() => import('../containers/Buy/FiatPaymentPending'))
  },
  {
    exact: true,
    path: 'novalnet/payment/failure',
    component: asyncComponent(() => import('../containers/Buy/FiatPaymentFailure'))
  },
  {
    exact: true,
    path: 'novalnet/payment/unconfirmed',
    component: asyncComponent(() => import('../containers/Buy/FiatPaymentUnconfirmed'))
  },
  {
    exact: true,
    path: 'my_investments',
    component: asyncComponent(() => import('../containers/Investments/Investments'))
  },
  {
    exact: true,
    path: 'mywallets/my_wallet',
    component: asyncComponent(() => import('../containers/MyWallet/MyWallet'))
  },
  {
    exact: true,
    path: 'mywallets/balance',
    component: asyncComponent(() => import('../containers/Balance/Balance'))
  },
  {
    exact: true,
    path: 'mywallets/transactions',
    component: asyncComponent(() => import('../containers/Transactions/Transactions'))
  },
  {
    exact: true,
    path: 'mywallets/trusted_address',
    component: asyncComponent(() => import('../containers/TrustedAddress/TrustedAddress'))
  },
  {
    exact: true,
    path: 'mywallets/returns',
    component: asyncComponent(() => import('../containers/ReturnInvestments/ReturnInvestments'))
  },
  {
    exact: true,
    path: 'account',
    component: asyncComponent(() => import('../containers/Account/Account'))
  },
  {
    exact: true,
    path: 'account/verification',
    component: asyncComponent(() => import('../containers/Account/Account'))
  },
  {
    exact: true,
    path: 'account/security',
    component: asyncComponent(() => import('../containers/Security/Security'))
  },
  {
    exact: true,
    path: 'account/security/devices/secure',
    component: asyncComponent(() => import('../containers/Security/Secure'))
  },
  {
    exact: true,
    path: 'account/security/devices/:deviceId',
    component: asyncComponent(() => import('../containers/Security/DeviceView'))
  },
  {
    exact: true,
    path: 'account/preferences',
    component: asyncComponent(() => import('../containers/Preferences/Preferences'))
  },
  {
    exact: true,
    path: 'account/my_preferences',
    component: asyncComponent(() => import('../containers/MyPreferences/MyPreferences'))
  },

  /* -------------------------- Admin Routes ------------------------ */
  {
    roles: ['ADMIN'],
    exact: true,
    path: 'manage/dashboard',
    component: asyncComponent(() => import('../containers/AdminDashboard/AdminDashboard'))
  },
  {
    roles: ['ADMIN'],
    exact: true,
    path: 'manage/users',
    component: asyncComponent(() => import('../containers/ManageUsers/ManageUsers'))
  },
  {
    roles: ['ADMIN'],
    exact: true,
    path: 'manage/users/:userId',
    component: asyncComponent(() => import('../containers/ManageUsers/User'))
  },
  {
    roles: ['ADMIN'],
    exact: true,
    path: 'manage/tokensale',
    component: asyncComponent(() => import('../containers/ManageTokenSales/ManageTokenSale'))
  },
  {
    roles: ['ADMIN'],
    exact: true,
    path: 'manage/token',
    component: asyncComponent(() => import('../containers/TokenSettings/TokenSettings'))
  },
  {
    roles: ['ADMIN'],
    exact: true,
    path: 'manage/tokensale/create',
    component: asyncComponent(() => import('../containers/ManageTokenSales/CreateTokenSale'))
  },
  {
    roles: ['ADMIN'],
    exact: true,
    path: 'manage/token_sale/:tokenSaleId',
    component: asyncComponent(() => import('../containers/ManageTokenSales/TokenSaleDetails'))
  },
  {
    roles: ['ADMIN'],
    exact: true,
    path: 'manage/investment_reports',
    component: asyncComponent(() => import('../containers/ManageInvestmentReports/InvestmentReports'))
  },
  {
    roles: ['ADMIN'],
    exact: true,
    path: 'manage/investment_reports/create',
    component: asyncComponent(() => import('../containers/ManageInvestmentReports/CreateInvestmentReport'))
  },
  {
    roles: ['ADMIN'],
    exact: true,
    path: 'manage/investment_reports/view/:reportId',
    component: asyncComponent(() => import('../containers/ManageInvestmentReports/InvestmentReportView'))
  },
  {
    roles: ['ADMIN'],
    exact: true,
    path: 'manage/timeline/create',
    component: asyncComponent(() => import('../containers/ManageTimeline/CreateTimeline'))
  },
  {
    roles: ['ADMIN'],
    exact: true,
    path: 'manage/timeline/view/:timelineId',
    component: asyncComponent(() => import('../containers/ManageTimeline/TimelineItemView'))
  },
  {
    roles: ['ADMIN'],
    exact: true,
    path: 'manage/timeline',
    component: asyncComponent(() => import('../containers/ManageTimeline/ManageTimeline'))
  },
  {
    roles: ['ADMIN'],
    exact: true,
    path: 'manage/refund',
    component: asyncComponent(() => import('../containers/RefundPage/RefundPage'))
  },
  {
    roles: ['ADMIN'],
    exact: true,
    path: 'manage/activity_log',
    component: asyncComponent(() => import('../containers/ActivityLog/TotalActivityLog.js'))
  },
  {
    roles: ['ADMIN'],
    exact: true,
    path: 'manage/build_info',
    component: asyncComponent(() => import('../containers/BuildInfo/BuildInfo.js'))
  },
  {
    roles: ['ADMIN'],
    exact: true,
    path: 'audit',
    component: asyncComponent(() => import('../containers/Audit/Audit'))
  },
  {
    roles: ['ADMIN'],
    exact: true,
    path: 'payment/sofort',
    component: asyncComponent(() => import('../containers/Stripe/SofortCheckoutPage'))
  },
  {
    roles: ['ADMIN'],
    exact: true,
    path: 'payment',
    component: asyncComponent(() => import('../containers/Stripe/Stripe'))
  },
  {
    roles: ['ADMIN'],
    exact: true,
    path: 'get_started',
    component: asyncComponent(() => import('../containers/ReceivingAddressPage/ReceivingAddressPage'))
  },
  {
    roles: ['ADMIN'],
    exact: true,
    path: 'settings',
    component: asyncComponent(() => import('../containers/Settings/Settings'))
  },
  {
    path: '',
    component: asyncComponent(() => import('../containers/Page/Inner404'))
  },
  /* -------------------------- Fallback ------------------------ */
];
export default routes;
