import {SET_MY_TRANSACTIONS, SET_USERS_TRANSACTIONS} from "./constants";

const initialState = {
  myTransactionsData: null,
  usersTransactionsData: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_MY_TRANSACTIONS: {
      return {...state, myTransactionsData: action.payload};
    }
    case SET_USERS_TRANSACTIONS: {
      return {...state, usersTransactionsData: action.payload};
    }
    default:
      return {...state};
  }
}