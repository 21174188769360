import React from 'react';
import {Route, Router} from 'react-router-dom';
import {connect} from 'react-redux';
import MainApp from './App/MainApp';
import asyncComponent from './helpers/AsyncFunc';
import PrivateAsyncRoute from './PrivateAsyncRoute';
import {Switch} from 'react-router';
import {history} from './history';
import RouteChangeHandler from './App/RouteChangeHandler';

const Routes = () => {
  return (
    <Router history={history}>
      <RouteChangeHandler>
        <Switch>
          <Route
            exact
            path={'/sign_in'}
            component={asyncComponent(() => import('./containers/LoginRegister/Login/LoginPage'))}
          />
          <Route
            exact
            path={'/sign_up'}
            component={asyncComponent(() => import('./containers/LoginRegister/SignUp/SignUp'))}
          />
          <Route
            exact
            path={'/help/supported_countries'}
            component={asyncComponent(() => import('./containers/Help/SupportedCountries'))}
          />
          <Route
            exact
            path={'/forgot_password'}
            component={asyncComponent(() =>
              import('./containers/LoginRegister/ForgotPassword/ForgotPassword')
            )}
          />
          <Route
            exact
            path={'/forgot_password/sent'}
            component={asyncComponent(() =>
              import('./containers/LoginRegister/ForgotPassword/EmailSent')
            )}
          />
          <Route
            exact
            path={'/forgot_password/change'}
            component={asyncComponent(() =>
              import('./containers/LoginRegister/ForgotPassword/NewPassword')
            )}
          />
          <Route
            exact
            path={'/forgot_password/changed'}
            component={asyncComponent(() =>
              import('./containers/LoginRegister/ForgotPassword/PasswordChanged')
            )}
          />
          <Route
            exact
            path={'/verify_email'}
            component={asyncComponent(() =>
              import('./containers/LoginRegister/SignUp/VerifyEmail')
            )}
          />
          <Route
            exact
            path={'/verify_email/resend'}
            component={asyncComponent(() =>
              import('./containers/LoginRegister/SignUp/ResendVerification')
            )}
          />
          <Route
            exact
            path={'/verify_email/expired'}
            component={asyncComponent(() =>
              import('./containers/LoginRegister/SignUp/VerifyExpired')
            )}
          />
          <Route
            exact
            path={'/verify_email/sent'}
            component={asyncComponent(() =>
              import('./containers/LoginRegister/SignUp/VerificationEmailSent')
            )}
          />
          <Route
            exact
            path={'/verify_email/verified'}
            component={asyncComponent(() => import('./containers/LoginRegister/SignUp/Verified'))}
          />
          <Route
            exact
            path={'/verify_email/verify'}
            component={asyncComponent(() =>
              import('./containers/LoginRegister/SignUp/VerifyEmail')
            )}
          />
          <Route
            exact
            path={'/unverified'}
            component={asyncComponent(() => import('./containers/LoginRegister/Login/Unverified'))}
          />
          <Route
            exact
            path={'/404'}
            component={asyncComponent(() => import('./containers/Page/Page404'))}
          />
          <Route
            exact
            path={'/500'}
            component={asyncComponent(() => import('./containers/Page/Page500'))}
          />
          <Route path="/" render={() => <PrivateAsyncRoute path="/" component={MainApp} />} />
        </Switch>
      </RouteChangeHandler>
    </Router>
  );
};

export default connect(() => ({}))(Routes);
