import React, { Component } from "react";
import { connect } from "react-redux";
import { Drawer, Icon, Layout } from "antd";
import ConstrainedContainer from "../components/ConstrainedContainer/ConstrainedContainer";
import LanguageSelector from "./LanguageSelector";
import TopAvatar from "./TopAvatar";
import ReceivingAddressWrapper from "./ReceivingAddressWrapper";
import SideMenus from "../containers/SideMenus/SideMenus";
import AlertWrapper from "./AlertWrapper";
import MainAppRouter from "./MainAppRouter";
import styled from "styled-components";
import customRoutes from "./routes";
import { checkIfVerified, giveAllStatus } from "../helpers/VerificationHelpers";
import { checkIsRequiredRole } from "../helpers/GenericHelpers";
import { withRouter } from "react-router";
import { FormattedMessage } from "react-intl";
import MyToastr from "../helpers/MyToastr";
import MyBalances from "../components/MyBalances/MyBalances";
import BalancePoller from "./BalancePoller";
import { MD768 } from "../components/Responsive";
import { Link } from "react-router-dom";
import AGENTS from "../constants/Agent";

const queryString = require("query-string");

const useSidebar = false;

const routes = [...customRoutes];

const { Header, Content, Footer, Sider } = Layout;

const Title = styled.div`
  min-width: 200px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: start;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const HeaderRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media only screen and (max-width: 599px) {
    margin-right: 15px;
  }
`;

function mapStateToProps(state) {
  return {
    me: state.User.me,
    skipped: state.Outer.skipped,
    role: state.User.roleData,
    myBalances: state.MyBalances.balanceData,
  };
}

class Inside extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0 };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps) {
    if (this.props.me && prevProps.me) {
      if (this.props.me.kyc_status !== prevProps.me.kyc_status) {
        if (
          this.props.me.kyc_status === "verified_by_idnow" &&
          (prevProps.me.kyc_status === "complete" ||
            prevProps.me.kyc_status === "pending_idnow_response" ||
            prevProps.me.kyc_status === "pending_idnow_final_confirmation")
        ) {
          MyToastr.success(
            <FormattedMessage id="idnow.verified" defaultMessage="Congratulation! You're verified!" />,
            <FormattedMessage id="idnow.verified.description" defaultMessage="You have been verified by IDnow." />,
          );
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  showDrawer = () => {
    this.setState({ visible: true });
  };

  onClose = () => {
    this.setState({ visible: false });
  };

  render() {
    const { myBalances } = this.props;
    const { url } = this.props.match;
    const { search } = this.props.location;
    const parsed = queryString.parse(search);
    const { agent } = parsed;
    const { width, visible } = this.state;

    const { me, skipped, history, role } = this.props;
    const verified = checkIfVerified(me.kyc_status);
    const isAdmin = checkIsRequiredRole(["ADMIN"], role);
    const allStatus = giveAllStatus(me.kyc_status);
    if (!verified && !isAdmin && agent !== AGENTS.WEB_VIEW) {
      if (!skipped) {
        if (allStatus === 0) {
          history.replace("/kyc/profile");
        } else if (allStatus === 1 || allStatus === 4) {
          history.replace("/kyc/identity");
        }
      }
    }

    return (
      <BalancePoller>
        {agent !== AGENTS.WEB_VIEW && (
          <Layout style={styles.layout}>
            {/* The header of the app inside */}
            <Header style={styles.mainNav}>
              <ConstrainedContainer>
                <HeaderContent>
                  <HeaderLeft>
                    {width < 768 ? (
                      <span className="h-start-flex text-white">
                        <Icon
                          className="trigger"
                          type={visible ? "menu-unfold" : "menu-fold"}
                          onClick={this.showDrawer}
                        />
                      </span>
                    ) : null}
                    {width >= 768 ? (
                      <Link to={"/dashboard"}>
                        <Title>
                          <img
                            src={window.COMPANY_IMAGE}
                            alt="logo"
                            style={{
                              float: "left",
                              height: "36px",
                              width: "36px",
                              margin: "0 15px 0 0",
                              filter: "brightness(0) invert(1)",
                            }}
                          />
                          <h2 className="text-white no-margin">{window.COMPANY_NAME}</h2>
                        </Title>
                      </Link>
                    ) : null}
                  </HeaderLeft>
                  <HeaderRight>
                    <MD768>
                      <MyBalances balances={myBalances} />
                    </MD768>
                    <LanguageSelector />
                    &nbsp;
                    <TopAvatar />
                  </HeaderRight>
                </HeaderContent>
              </ConstrainedContainer>
            </Header>

            {/* The content of the app inside */}
            <Content>
              <ReceivingAddressWrapper>
                <Layout style={{ height: "calc(100% - 70px)" }}>
                  {width > 768 ? (
                    useSidebar ? (
                      <Sider trigger={null} collapsible={true} width={270} style={{ background: "#fff" }}>
                        <SideMenus />
                      </Sider>
                    ) : (
                      <Header style={styles.horizontalMenus}>
                        <ConstrainedContainer>
                          <SideMenus mode="horizontal" />
                        </ConstrainedContainer>
                      </Header>
                    )
                  ) : (
                    <Drawer
                      className="side-drawer"
                      bodyStyle={{ padding: "0 0 100px 0" }}
                      title={
                        <div style={{ display: "flex", alignItems: "start", flexDirection: "column" }}>
                          <Link to={"/dashboard"}>
                            <Title>
                              <img
                                src={window.COMPANY_IMAGE}
                                alt="logo"
                                style={{
                                  float: "left",
                                  height: "36px",
                                  width: "36px",
                                  margin: "0 15px 0 0",
                                  filter: "brightness(0) invert(1)",
                                }}
                              />
                              <h2 className="text-white no-margin">{window.COMPANY_NAME}</h2>
                            </Title>
                          </Link>
                          <br />
                          <MyBalances balances={myBalances} vertical />
                        </div>
                      }
                      placement="left"
                      closable={false}
                      width={270}
                      onClose={this.onClose}
                      visible={visible}
                    >
                      <SideMenus onClick={this.onClose} />
                    </Drawer>
                  )}

                  <Content style={width > 600 ? styles.content : styles.contentMobile}>
                    <ConstrainedContainer>
                      <AlertWrapper>
                        <MainAppRouter url={url} routes={routes} />
                      </AlertWrapper>
                    </ConstrainedContainer>
                  </Content>
                </Layout>
              </ReceivingAddressWrapper>
            </Content>

            {/* The footer of the app inside */}
            <Footer style={{ textAlign: "center" }}>{window.COMPANY_NAME} ©2020</Footer>
          </Layout>
        )}

        {agent === AGENTS.WEB_VIEW && <MainAppRouter url={url} routes={routes} />}
      </BalancePoller>
    );
  }
}

const styles = {
  layout: {
    minHeight: "100vh",
  },
  mainNav: {
    background: "#009bc3",
    height: "70px",
  },
  horizontalMenus: {
    height: "auto",
    background: "#fff",
    borderBottom: "1px solid #e8e8e8",
    boxShadow: "0px 3px 15px 0px rgba(0,0,0,0.05)",
    zIndex: 900,
  },
  content: {
    background: "#f4f7fa",
    padding: "30px",
    minHeight: "280px",
  },
  contentMobile: {
    padding: "30px 15px",
  },
};

export default withRouter(connect(mapStateToProps)(Inside));
