import React, {Component} from 'react';
import './ConstrainedContainer.css';

class ConstrainedContainer extends Component {
  render() {
    const {children} = this.props;
    return (
      <div style={styles.outerFlex}>
        <div className="constrained-720">
          {children}
        </div>
      </div>
    );
  }
}

const styles = {
  outerFlex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%'
  }
};

export default ConstrainedContainer;