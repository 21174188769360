import {baseApi} from "./baseurl";
/* -------------------- Current api state --------------------*/

export const currentApiServerApi = baseApi + '/api/v1';
export const currentAuthApi = baseApi + "/auth/api/v1/auth";
export const currentWalletApi = baseApi + "/wallet/api";

export const newAuthApi = baseApi + "/auth/api/v1";


/* -------------------- Status --------------------*/
export const statusServerUserApi = {api: currentAuthApi + "/status"};

/* -------------------- Authentication api for 2fa server --------------------*/
export const pingApi = {api: currentAuthApi, label: 'PING'};
export const loginConfirmApi = {api: currentAuthApi + "/2fa/login/confirm", label: 'LOGIN_CONFIRM'};
export const sendLoginSMSApi = {api: currentAuthApi + "/2fa/login/sms", label: 'SEND_LOGIN_SMS'};
export const loginApi = {api: currentAuthApi + "/2fa/login/trigger", label: 'LOGIN_TRIGGER'};
export const enable2FaApi = {api: currentAuthApi + "/2fa/record", label: 'ENABLE_2FA'};
export const confirm2faApi = {api: currentAuthApi + "/2fa/record/confirm", label: 'CONFIRM_2FA'};
export const signOutApi = {api: currentAuthApi + "/signout", label: 'SIGN_OUT'};
export const registerApi = {api: currentAuthApi + "/signup/register", label: 'REGISTER'};
export const forgotPasswordApi = {api: currentAuthApi + "/forgetpassword", label: 'FORGOT_PASSWORD'};
export const changePasswordApi = {api: currentAuthApi + '/changepassword', label: 'CHANGE_PASSWORD'};
export const resendVerificationApi = {api: currentAuthApi + '/signup/resend_confirmation'};
export const tfaStatusApi = {api: currentAuthApi + '/2fa/mystatus', label: 'TFA_STATUS'};
export const sessionStatus = {api: currentAuthApi + "/status", label: 'SESSION_STATUS'};

export function get2faStatusOfUserApi(userId) {
  return {api: currentAuthApi + "/2fa/status/" + window.projectId + "/" + userId, method: "GET"};
}

/* -------------------- Jumio Netverify api --------------------*/

export const documentPreInfoApi = `${baseApi}/docverify/supported_type`;
export const documentVerifyStartApi = `${baseApi}/docverify/request`;
export const netverifyApi = `${currentApiServerApi}/netverify/request`;

/* -------------------------- Wallet and Trusted Address apis ------------------------ */

export const getWalletDetailsApi = {api: currentWalletApi + '/wallet', label: 'GET_WALLET_DETAILS'};
export const getTrustedAddressApi = {api: currentWalletApi + '/wallet/addresses'};
export const addTrustedAddressApi = {api: currentWalletApi + '/wallet/addresses', method: "PUT"};
export const deleteTrustedApi = {api: currentWalletApi + '/wallet/addresses', method: "DELETE"};
export const archiveTrustedApi = {api: currentWalletApi + '/wallet/addresses', method: "DELETE"};
export const getArchivedAddressApi = { api: currentWalletApi + '/wallet/addresses/archived', method: "GET"};
export const setReceivingAddressApi = {api: currentWalletApi + '/wallet/address/receiving', method: "POST"};
export const getReceivingAddressApi = {api: currentWalletApi + '/wallet/addresses/receiving', method: "GET"};
export const getTransactionsApi = {api: currentWalletApi + '/wallet/transactions', method: 'GET'};

export function refreshBalanceApi(address) {
  return {api: currentWalletApi + '/wallet/address/' + address + '/sync', method: "POST"};
}

export const pingWalletGetDetailsApi = {api: currentWalletApi + '/ping/info.php', method: "GET"};

/* -------------------------- Token Sale apis ------------------------ */

export const getTokenDetailsApi = {api: currentWalletApi + '/tokensale/token'};
export const getTokenSaleApi = {api: currentWalletApi + '/tokensale'};
export const createTokenSaleApi = {api: currentWalletApi + '/tokensale'};
export const getAllTokenSaleApi = {api: currentWalletApi + '/tokensale/all', method: "GET"};
export const startTokenSaleApi = {api: currentWalletApi + '/tokensale/start', method: "POST"};
export const stopTokenSaleApi = {api: currentWalletApi + '/tokensale/stop', method: "POST"};
export const equivalenceApi = {api: currentWalletApi + '/tokensale/token', method: "PATCh"};
export const recalculateEquivalenceApi = {api: currentWalletApi + '/tokensale/token/re-calculate', method: "POST"};
export const disableAutoPriceApi = {api: currentWalletApi + '/tokensale/token/price-update/disable', method: "POST"};
export const enableAutoPriceApi = {api: currentWalletApi + '/tokensale/token/price-update/enable', method: "POST"};
export const defaultTokenSaleApi = {api: currentWalletApi + '/tokensale/default', method: "POST"};


/* -------------------------- User ------------------------ */

export const setKycApi = {api: currentApiServerApi + '/user/kyc', method: "PUT"};
export const getKycApi = {api: currentApiServerApi + '/user/kyc', method: "GET"};
export const getUserListApi = {api: currentApiServerApi + '/user/list', method: 'GET'};

/* -------------------------- Investments ------------------------ */

export const getInvestmentsApi = {api: currentWalletApi + '/tokensale/investment', method: "GET"};

/* -------------------------- Stripe charge ------------------------ */

export const stripeChargeApi = {api: currentApiServerApi + "/stripe_payment/charge"};

/* -------------------------- AdminChanging user Statuses ------------------------ */

export const changeUserStatusApi = {api: newAuthApi + '/admin/user/status', method: "PATCH"};

/* -------------------------- Timeline ------------------------ */

export const getTimelineApi = {api: currentApiServerApi + '/user/timeline', method: "GET"};
export const createTimelineApi = {api: currentApiServerApi + '/admin/timeline', method: "POST"};
export const updateTimelineApi = {api: currentApiServerApi + '/admin/timeline/', method: "PUT"};
export const deleteTimeLineApi = {api: currentApiServerApi + '/admin/timeline/', method: "DELETE"};
export const getTimelineItemApi = {api: currentApiServerApi + '/user/timeline/', method: "GET"};

/* -------------------------- Media files ------------------------ */

export const uploadMediaApi = {api: currentApiServerApi + '/user/media', method: "POST"};

export function downloadApi(userId, resourceId) {
  return {api: currentApiServerApi + '/user/' + userId + '/media/' + resourceId, method: "GET"};
}

/* -------------------- Countries --------------------*/
export const updateCountryApi = {api: currentApiServerApi + '/admin/country/', method: "PUT"};
export const getCountriesApi = {api: currentApiServerApi + '/user/country', method: "GET"};

/* -------------------- User show --------------------*/
export const getUserShowListApi = {api: currentApiServerApi + '/user', method: "GET"};

/* -------------------- Investment reports --------------------*/
export const createInvestmentReportApi = {api: currentApiServerApi + '/admin/reports', method: "POST"};
export const getOneInvestmentReportApi = {api: currentApiServerApi + '/user/reports/', method: "GET"};

export const publicCountryApi = {api: currentApiServerApi + '/public/country', method: "GET"};

/* -------------------- Application settings --------------------*/
export const applicationSettingsApi = {api: currentWalletApi + '/settings.php'};

/* -------------------- Refund btc --------------------*/
export const requestRefundBtcApi = {api: currentWalletApi + "/tokensale/investment/refund", method: "POST"};
export const refundBtcApi = {api: currentWalletApi + "/tokensale/investment/refund", method: "POST"};
export const refundBtcFiatApi = {api: currentWalletApi + "/tokensale/investment/refund/payment", method: "POST"};
export const refundApi = {api: currentWalletApi + '/tokensale/investment/refund', method: "POST"};
export const requestRefundApi = {api: currentWalletApi + '/tokensale/investment/refund/request.php', method: "POST"};
export const cancelRequestRefundApi = {api: currentWalletApi + '/tokensale/investment/refund/request.php', method: "DELETE"};

/* -------------------- Id now --------------------*/
export const idnowEligibilityApi = {api: currentApiServerApi + "/idnow/eligibility", method: "GET"};
export const idnowInitiateApi = {api: currentApiServerApi + "/idnow/initiate", method: "GET"};
export const idnowGetDetailsApi = {api: currentApiServerApi + "/idnow/getdetails", method: "GET"};

/* -------------------- csv --------------------*/
export const exportTransactionCsv = {
  api: currentWalletApi + "/wallet/transactions/report.csv?separator=,",
  method: "GET"
};
export const exporInvestmentCsv = {
  api: currentWalletApi + "/tokensale/investment/report.csv?separator=,",
  method: "GET"
};

/* -------------------- heartbeat --------------------*/
export const heartBeatApiUrl = {api: currentAuthApi + '/heartbeat', method: "GET"};

/* -------------------- Fee --------------------*/
export const feeApi = {api: baseApi + '/wallet/report/transaction_fee.php', method: "GET"};

/* -------------------- skip kyc --------------------*/
export function skipKycApi(uuid) {
  return {api: currentApiServerApi + '/user/' + uuid + '/kyc/skip', method: "GET"}
}

/* -------------------- Activity Log --------------------*/
export const activityLogApi = {api: currentApiServerApi + '/activities', method: "POST"};

/* -------------------- KlickTipp --------------------*/
export function getUsersKlickTippApi(userId) {
  return {api: currentApiServerApi + '/user/' + userId + '/klicktipp', method: "GET"};
}

/* -------------------- Sources --------------------*/
export const getMySourcesApi = {api: currentAuthApi + '/sessions', method: "GET"};

export function getASourceApi(id) {
  return {api: currentAuthApi + '/sessions/' + id, method: "GET"};
}

export function validateSourceApi(id) {
  return {api: currentAuthApi + '/sessions/' + id, method: "PATCH"};
}

export function removeSourceApi(id) {
  return {api: currentAuthApi + '/sessions/' + id, method: "DELETE"};
}

/* -------------------- stats --------------------*/
export const getUserStatsApi = {api: currentApiServerApi + '/admin/stats', method: "GET"};
export const get2faStatsApi = {api: baseApi + '/auth/api/v1/admin/stats', method: "GET"};
export const getMessageBirdBalanceApi = {api: baseApi + '/auth/api/v1/admin/messagebird/balance', method: "GET"};
export const getMessageBirdDetailsApi = {api: baseApi + '/auth/api/v1/admin/messagebird/stats', method: "GET"};
export const getWalletsStatApi = {api: baseApi + '/wallet/report/dashboard.php', method: "GET"};

/* -------------------- My preferences --------------------*/
export const myPreferencesApi = {api: currentApiServerApi + '/user/preference', method: "PUT"};
export const changeEmailApi = {api: newAuthApi + '/admin/user/changeemail', method: "POST"};

/* -------------------- Admin settings --------------------*/
export const generalAdminSettingsApi = {api: currentApiServerApi + '/admin/settings', method: "GET"};
export const publicGeneralSettingsApi = {api: currentApiServerApi + '/public/settings/all', methods: "GET"};
export const putGeneralAdminSettingApi = {api: currentApiServerApi + '/admin/settings', method: "PUT"};

/* -------------------- Build info --------------------*/
export const clientBuildInfoApi = {api: baseApi + "/build-info.json", method: "GET"};
export const walletBuildApi = {api: currentWalletApi + '/build-info.json', method: "GET"};
export const apiServerBuildApi = {api: currentApiServerApi + '/build-info.json', method: "GET"};
export const authServerApi = {api: baseApi + '/auth/api/v1/build-info.json', method: "GET"};


/* -------------------- Balance only --------------------*/
export const walletBalanceApi = {api: currentWalletApi + '/wallet/balance', method: "GET"};

/* -------------------- Buy settings --------------------*/
export const buySettingsApi = {api: currentApiServerApi + ''};

/* -------------------- terms and confitions --------------------*/
export const publicTermsAndConditionApi = {
  api: currentApiServerApi + '/public/settings/termsandconditions',
  method: "GET"
};

/* -------------------- prospectus --------------------*/
export const prospectusApi = {api: currentApiServerApi + '/public/settings/prospectus', method: "GET"};
export const readProspectusApi = {api: currentApiServerApi + '/user/read_prospectus', method: "POST"};

/* -------------------- user content --------------------*/
export const userConsentApi = { api : currentApiServerApi + '/user/user_consent', method: "POST"};
export const userConsentChangeByAdminApi = {api : currentApiServerApi + '/admin/user', method: "PATCH"};

/* -------------------- Investment report --------------------*/
export const walletCreateInvestmentReportApi = {api: currentWalletApi + '/returns/report.php', method: "POST"};
export const getWalletReportsApi = {api: currentWalletApi + '/returns/report.json', method: "GET"};
export const updateWalletReportApi = {api: currentWalletApi + '/returns/report.php', method: "PATCH"};
export const deleteWalletReportApi = {api: currentWalletApi + '/returns/report.php', method: "DELETE"};
export const takeSnapshotApi = {api: currentWalletApi + '/returns/snapshot.php', method: "POST"};
export const returnsDeliveryApi = {api: currentWalletApi + '/returns/delivery.json', method: "GET"};
export const transerDeliveryApi = {api: currentWalletApi + '/returns/transfer.php', method: "POST"};
export const certificateApi = {api: currentWalletApi + '/returns/ipfs/certificate.pdf', method: "GET"};


/* -------------------- IDnow updates --------------------*/
export const updateUserByAdminApi = (userId) => {
  return {api: currentApiServerApi + '/admin/user/' + userId , method: "PATCH"}
};


/* -------------------- oauth --------------------*/
export const createOauthClientApi = {api: newAuthApi + '/admin/oauth2/client', method: "POST"};
export const getOAuthClientApi = {api: newAuthApi + '/admin/oauth2/client', method: "GET"};

export function deleteOAuthClientApi(clientId) {
  return {api: newAuthApi + '/admin/oauth2/client/' + clientId, method: "DELETE"};
}

export function editOAuthClientApi(clientId) {
  return {api: newAuthApi + '/admin/oauth2/client/' + clientId, method: "DELETE"};
}

/* -------------------- Whitelisted admin ip --------------------*/
export const getWhiteListedAdminIpApi = {api: newAuthApi + '/admin/whitelisted_ip', method: "GET" };
export const createWhitelistedAdminIpApi = { api: newAuthApi + '/admin/whitelisted_ip', method: "POST"};
export const deleteWhitelistedAdminIpApi = {api: newAuthApi + '/admin/whitelisted_ip', method: "DELETE"};

/* -------------------- Novalnet --------------------*/
export const initNovalnetPaymentApi = {api: currentApiServerApi + '/novalnet/pay', method: "POST"};
export const getInvoiceApi = {api: currentApiServerApi + '/novalnet/invoice', method: "GET"};

/* -------------------- Novalnet triggers --------------------*/
export const getAllNovalnetPaymentsApi = {api: currentApiServerApi + '/novalnet/payments', method: "GET"};
export const updateNovalnetPaymentApi = {api: currentApiServerApi + '/novalnet/payments', method: "PUT"};
export const updateNovalnetPaymentTotalApi = {api: currentApiServerApi + '/novalnet/payments-configuration', method: "PUT"};


/* -------------------- Googl tag manager --------------------*/
export const getGtmTriggersApi = { api: currentApiServerApi + '/user/gttm', method: 'GET'};
export const patchGtmTriggerApi = tagId => {
  return {api: currentApiServerApi + '/user/gttm/tags/' + tagId, method: "PATCH"};
}
