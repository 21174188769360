import {SET_MY_BALANCE} from "./constants";

const initialState = {
  balanceData: {
    token: 0,
    eth: 0,
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_MY_BALANCE: {
      return {...state, balanceData: action.payload};
    }
    default:
      return {...state};
  }
}