import {dummyInvestments} from "../../constants/DummyData";

const initialState = {
  investmentsData: dummyInvestments
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ('SET_INVESTMENTS'): {
      return {...state, investmentsData: action.payload};
    }
    default:
      return {...state};
  }
}