import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import {createLogger} from 'redux-logger';
import {routerMiddleware, routerReducer} from 'react-router-redux';
import reducers from './reducers';
import {history} from '../history';
import ApiMiddleware from "./ApiMiddleware";

const routeMiddleware = routerMiddleware(history);
const loggerMiddleware = createLogger();
const middlewares = [thunk, routeMiddleware, loggerMiddleware, ApiMiddleware];

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    })
    : compose;

const appReducer = combineReducers({
  ...reducers,
  router: routerReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_REDUX') {
    const {Language} = state;
    state = {Language};
  }
  return appReducer(state, action)
};

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);
export default store;
