import React, { Component } from "react";
import { connect } from "react-redux";
import ConstrainedContainer from "../components/ConstrainedContainer/ConstrainedContainer";
import { Layout } from "antd";
import LanguageSelector from "./LanguageSelector";
import TopAvatar from "./TopAvatar";
import styled from "styled-components";
import { withRouter } from "react-router";
import AGENTS from "../constants/Agent";

const queryString = require("query-string");

const { Header, Content } = Layout;

const Title = styled.h1`
  min-width: 200px;
  color: white;
  margin-top: 15px;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const HeaderRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

function mapStateToProps() {
  return {};
}

class OutsideLayoutWrapper extends Component {
  render() {
    const { search } = this.props.location;
    const parsed = queryString.parse(search);
    const { agent } = parsed;

    return (
      <div>
        <Layout style={styles.layout}>
          {agent !== AGENTS.WEB_VIEW && (
            <Header style={styles.mainNav}>
              <ConstrainedContainer>
                <HeaderContent>
                  <HeaderLeft>
                    <Title>{window.COMPANY_NAME}</Title>
                  </HeaderLeft>
                  <HeaderRight>
                    <LanguageSelector />
                    <TopAvatar hideAccount />
                  </HeaderRight>
                </HeaderContent>
              </ConstrainedContainer>
            </Header>
          )}
          <Content style={styles.content}>{this.props.children}</Content>
        </Layout>
      </div>
    );
  }
}

const styles = {
  layout: {
    minHeight: "100vh",
  },
  mainNav: {
    background: "#009bc3",
    height: "70px",
  },
  horizontalMenus: {
    height: "auto",
    background: "#fff",
    borderBottom: "1px solid #e8e8e8",
  },
  content: {
    // background: '#f4f7fa',
    background: "white",
    padding: 0,
    minHeight: "280px",
  },
  contentMobile: {
    padding: "30px 15px",
  },
};

export default withRouter(connect(mapStateToProps)(OutsideLayoutWrapper));
