import React, {Component} from 'react';
import {connect} from 'react-redux';
import {addLocaleData, IntlProvider} from "react-intl";

import en from 'react-intl/locale-data/en';
import eu from 'react-intl/locale-data/eu';
import de from 'react-intl/locale-data/de';
import nl from 'react-intl/locale-data/nl';

import messages_de from "./i18n/de.json";
import messages_en from "./i18n/en.json";
import messages_nl from "./i18n/nl.json";
import en_US from 'antd/lib/locale-provider/en_US';
import nl_NL from 'antd/lib/locale-provider/nl_NL';
import de_DE from 'antd/lib/locale-provider/de_DE';
import {LocaleProvider} from "antd";


const messages = {
  'eu': messages_en,
  'nl': messages_nl,
  'de': messages_de,
  'en': messages_en,
};
const language = navigator.language.split(/[-_]/)[0];  // language without region code

addLocaleData([...eu, ...en, ...de, ...nl]);

export const {intl} = new IntlProvider({locale: language, messages: messages}, {}).getChildContext();

function mapStateToProps(state) {
  return {
    selectedLanguage: state.Language.selectedLanguage
  };
}

class LanguageWrapper extends Component {
  render() {
    const localeMapper = {
      en: en_US,
      nl: nl_NL,
      de: de_DE,
    };

    const selected = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
    const {selectedLanguage} = this.props;
    return (
      <IntlProvider locale={selected} messages={messages[selected]} >
        <LocaleProvider
          locale={
            localeMapper[selectedLanguage]
          }
        >
        {this.props.children}
        </LocaleProvider>
      </IntlProvider>
    );
  }
}

export default connect(
  mapStateToProps,
)(LanguageWrapper);