import api from '../../api/index';
import {actionFailure, actionStart, actionSuccess} from "../Generic/actions";
import {LOGOUT} from "../Api/constants";
import {history} from '../../history';
import axios from 'axios';
import {STATUS_SERVER_USER} from "./constants";
import {resetRedux} from "../actions";

export function statusServerUser() {
  return (dispatch) => {
    dispatch(actionStart(STATUS_SERVER_USER));
    const apiCall = api.statusServerUserApiCall();
    apiCall
      .then(() => {
        dispatch(actionSuccess(STATUS_SERVER_USER));
      })
      .catch(() => {
        dispatch(actionFailure(STATUS_SERVER_USER));
      });
    return apiCall;
  }
}

export function setLogIn(authentication) {
  return dispatch => {
    return dispatch({type: 'SET_LOGIN', payload: authentication});
  }
}

export function setLogOut(authentication) {
  return (dispatch) => {
    dispatch({type: 'SET_LOGOUT', payload: authentication});
    dispatch(resetRedux());
    history.push("/sign_in");
    window.dataLayer
    && window.dataLayer.push({
      'event': 'TRIGGER_USER_ID_SET',
      'userId' : undefined
    });
    return Promise.resolve();
  }
}

export function logOut() {
  return (dispatch) => {
    axios.cancelAll();
    dispatch(actionStart(LOGOUT));
    const apiCall = api.logOut();
    apiCall
      .then(() => {
        dispatch(setLogOut());
        history.push("/sign_in");
        dispatch(actionSuccess(LOGOUT));
      })
      .catch(() => {
        dispatch(actionFailure(LOGOUT));
      });
    return apiCall;
  }
}