import React, {Component} from 'react';
import {connect} from 'react-redux';
import OutsideLayoutWrapper from "./OutsideLayoutWrapper";
import {outerRoutes} from "./outerRoutes";
import MainAppRouter from "./MainAppRouter";
import {withRouter} from "react-router";

const routes = [
  ...outerRoutes,
];

function mapStateToProps() {
  return {};
}

class Outside extends Component {
  render() {
    const {url} = this.props.match;
    return (
      <OutsideLayoutWrapper>
        <MainAppRouter url={url} routes={routes}/>
      </OutsideLayoutWrapper>
    );
  }
}

export default withRouter(connect(
  mapStateToProps,
)(Outside));