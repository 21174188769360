import {SET_USER_WALLET} from "./constants";

const initialState = {
  userWalletData: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_WALLET: {
      return {...state, userWalletData: action.payload};
    }
    default:
      return {...state};
  }
}