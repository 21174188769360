import {
  dummyDocumentList,
  dummyInvestmentReport,
  dummyInvestmentReportList,
  dummySendUsers
} from "../../constants/DummyData";
import {SET_RETURNS_DELIVERY, SET_WALLET_REPORTS, SET_WALLET_REPORTS_VIEW} from "./constants";

const initialState = {
  investmentReportListData: dummyInvestmentReportList,
  sendUsersData: dummySendUsers,
  investmentReportData: dummyInvestmentReport,
  documentListData: dummyDocumentList,
  reportViewData: dummyInvestmentReport,

  walletReportsData: null,
  walletReportsViewData: null,
  returnsDeliveryData: null,

};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ('SET_INVESTMENT_REPORT_LIST'): {
      return {...state, returnInvestmentsData: action.payload};
    }
    case ('SET_SEND_USERS'): {
      return {...state, sendUsersData: action.payload};
    }
    case('SET_INVESTMENT_REPORT') : {
      return {...state, investmentReportData: action.payload};
    }
    case('SET_DOCUMENT_LIST') : {
      return {...state, investmentReportData: action.payload};
    }
    case('SET_REPORT_VIEW') : {
      return {...state, reportViewData: action.payload};
    }
    case SET_WALLET_REPORTS : {
      return {...state, walletReportsData: action.payload};
    }
    case SET_WALLET_REPORTS_VIEW : {
      return {...state, walletReportsViewData: action.payload}
    }
    case SET_RETURNS_DELIVERY  : {
      return {...state, returnsDeliveryData: action.payload}
    }

    default:
      return {...state};
  }
}