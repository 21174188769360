import React, {Component} from 'react';
import './App.css';
import {StripeProvider} from 'react-stripe-elements';
import './constants/Globals';

import {Provider} from "react-redux";
import {ThemeProvider} from "styled-components";
import axios from 'axios';
import Cookies from 'universal-cookie';
import axiosCancel from 'axios-cancel';
import store from "./redux/store";
import Routes from "./router";
import {theme} from "./constants/theme";
import {stripeApiKey} from "./constants/keys";
import LanguageWrapper from "./LanguageWrapper";
import {currentApiServerApi} from "./api/apiurls";
import NetworkService from "./NetworkService";

import moment from "moment";
import "antd/dist/antd.less";
import {Aux} from "./Aux";


axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;
axios.defaults.accessControlAllowOrigin = "*";
axios.defaults.headers.common = {
  "Accept-Language": localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
};

axiosCancel(axios, {
  debug: false // default
});

moment.locale(localStorage.getItem('language') ? localStorage.getItem('language') : 'en');

NetworkService.setupInterceptors(store);

const cookies = new Cookies();

class App extends Component {
  componentDidMount() {
    const language = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
    cookies.set('language', language, {path: '/'});
    axios.get(`${currentApiServerApi  }/public/ping?changelang=${  language}`);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    console.log("ROUTE CHANGED");
  }

  render() {


    return (
      <ThemeProvider theme={theme}>
        <StripeProvider apiKey={stripeApiKey}>
          <Provider store={store} >
            <LanguageWrapper>
                <Aux>
                  <Routes/>
                  {/* <LanguageFromUrl> */}
                  {/* <LanguageChangerFromUrlWrapper> */}
                  {/* <Routes /> */}
                  {/* </LanguageChangerFromUrlWrapper> */}
                  {/* </LanguageFromUrl> */}
                </Aux>
            </LanguageWrapper>
          </Provider>
        </StripeProvider>
      </ThemeProvider>
    );
  }
}

export default App;
