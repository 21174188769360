import {SET_ACTIVITY_LOG} from "./constants";

const initialState = {
  activityLogData: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVITY_LOG: {
      return {...state, activityLogData: action.payload};
    }
    default:
      return {...state};
  }
}