import {SET_COUNTRIES, SET_WHITELISTED_COUNTRIES} from "./constants";

const initialState = {
  countriesData: [],
  whiteListedData: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_COUNTRIES: {
      return {...state, countriesData: action.payload};
    }
    case SET_WHITELISTED_COUNTRIES: {
      return {...state, whiteListedData: action.payload};
    }
    default:
      return {...state};
  }
}