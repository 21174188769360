import {dummyReturnInvestmentsData} from "../../constants/DummyData";
import {SET_USER_RETURNS} from "./constants";

const initialState = {
  returnInvestmentsData: dummyReturnInvestmentsData,
  returnsUserData: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ('SET_RETURN_INVESTMENTS'): {
      return {...state, returnInvestmentsData: action.payload};
    }
    case SET_USER_RETURNS  : {
      return {...state, returnsUserData: action.payload}
    }
    default:
      return {...state};
  }
}