import {actionFailure, actionStart, actionSuccess} from "../Generic/actions";
import api from "../../api/index";
import {
  FETCH_2FA_STATUS_OF_USER,
  FETCH_KYC,
  FETCH_ME,
  FETCH_OTHER,
  RESET_2FA_STATUS_OF_USER,
  SET_2FA_STATUS_OF_USER,
  SET_KYC
} from "./constants";
import _ from 'lodash';
import {getSortedChildren} from "../../helpers/Utils";

export function setUser(user) {
  return (dispatch) => {
    dispatch({type: 'SET_USER', payload: user});
  }
}


export function setMe(user) {
  return (dispatch, getState) => {
    const state = getState();
    const storedMe = getSortedChildren(_.omit(state.User.me, ['idnow_attempt']));
    const newMe = getSortedChildren(_.omit(user, ['idnow_attempt']));
    if (!_.isEqual(storedMe, newMe)) {
      dispatch({type: 'SET_ME', payload: user});
    }
  }
}

export function setOther(user) {
  return (dispatch) => {
    dispatch({type: 'SET_OTHER', payload: user});
  }
}

export function setMyKyc(kyc) {
  return (dispatch) => {
    dispatch({type: 'SET_KYC', payload: kyc});
  }
}

export function setTfaStatusOfUser(status) {
  return (dispatch) => {
    dispatch({type: SET_2FA_STATUS_OF_USER, payload: status});
  }
}

export function resetTfaStatusOfUser() {
  return (dispatch) => {
    dispatch({type: RESET_2FA_STATUS_OF_USER, payload: null})
  }
}

export function setRole(roles) {
  return (dispatch) => {
    dispatch({type: 'SET_ROLE', payload: roles});
    return Promise.resolve();
  }
}

export function setKyc(data) {
  return (dispatch) => {
    dispatch(actionStart(SET_KYC));
    const apiCall = api.setKycApiCall(data);
    apiCall
      .then(() => {
        dispatch(actionSuccess(SET_KYC));
      })
      .catch(() => {
        dispatch(actionFailure(SET_KYC));
      });
    return apiCall;
  }
}

export function fetchKyc(id) {
  return (dispatch) => {
    dispatch(actionStart(FETCH_KYC));
    const apiCall = api.getKycApiCall(id);
    apiCall
      .then((response) => {
        dispatch(setMyKyc(response.data));
        dispatch(actionSuccess(FETCH_KYC));
      })
      .catch(() => {
        dispatch(actionFailure(FETCH_KYC));
      });
    return apiCall;
  }
}

export function fetchOther(id) {
  return (dispatch) => {
    dispatch(actionStart(FETCH_OTHER));
    const apiCall = api.getKycApiCall(id);
    apiCall
      .then((response) => {
        dispatch(setOther(response.data));
        dispatch(actionSuccess(FETCH_OTHER));
      })
      .catch(() => {
        dispatch(actionFailure(FETCH_OTHER));
      });
    return apiCall;
  }
}

export function fetchAUserInList(id) {
  return () => {
    const apiCall = api.getKycApiCall(id);
    apiCall
      .then(() => {
      })
      .catch(() => {
      });
    return apiCall;
  }
}

export function fetchMe() {
  return (dispatch) => {
    dispatch(actionStart(FETCH_ME));
    const apiCall = api.getKycApiCall();
    apiCall
      .then((response) => {
        dispatch(setMe(response.data));
        dispatch(actionSuccess(FETCH_ME));
      })
      .catch(() => {
        dispatch(actionFailure(FETCH_ME));
      });
    return apiCall;
  }
}

export function fetch2faStatusOfUser(userId) {
  return (dispatch) => {
    dispatch(resetTfaStatusOfUser());
    dispatch(actionStart(FETCH_2FA_STATUS_OF_USER));
    const apiCall = api.get2faStatusOfUserApiCall(userId);
    apiCall
      .then((response) => {
        dispatch(setTfaStatusOfUser(response.data));
        dispatch(actionSuccess(FETCH_2FA_STATUS_OF_USER));
      })
      .catch(() => {
        dispatch(actionFailure(FETCH_2FA_STATUS_OF_USER));
      });
    return apiCall;
  }
}